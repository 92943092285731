import React, { useEffect, useState, useRef } from 'react'
import { Button, IconButton, TextField } from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Cookies from 'js-cookie';
import AudioRecorder from './AudioRecorder';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import usePrescriptionStore from '../DataRepository/Prescription';
import axios from 'axios';
import { InputAdornment } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { toast, Zoom } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css'; // Import default styles
import Slide from '@mui/material/Slide';
import Patient_Profile from '../Appointments/Patient_Profile';
import { useNavigate } from 'react-router-dom';
import Appointments from '../Appointments/Appointments';
import { profileModel, shopModel, teleConsultationModel } from '../Axios/AxiosConfig';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function UploadPrescription() {
    let { selectedPatientdetails, selectedAppointemntdetails, audioBase64, setSelectedPatientdetails } = usePrescriptionStore()
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);

    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(null);
    const [currentTime, setCurrentTime] = useState(0); // Track current time
    const [duration, setDuration] = useState(0); // Track audio duration
    const [originalTranscript, setOriginalTranscript] = useState('');

    const handleTranscriptChange = (event) => {
        setTranscript(event.target.value);
    };

    const handleAudioPlayPause = () => {
        const audio = audioRef.current;

        if (isPlaying) {
            audio.pause();
        } else {
            audio.play()
                .then(() => {
                    setIsPlaying(true);
                })
                .catch((error) => {
                    console.error("Audio play error: ", error);
                });
        }
    };

    const handleAudioEnded = () => {
        setIsPlaying(false); 
    };
    const handleTimeUpdate = () => {
        setCurrentTime(audioRef.current.currentTime);
    };

    const handleSliderChange = (e) => {
        const newTime = e.target.value;
        audioRef.current.currentTime = newTime; // Update the audio's current time when the slider is moved
        setCurrentTime(newTime); 
    };

    const handleLoadedMetadata = () => {
        setDuration(audioRef.current.duration); 
    };

    const navigate = useNavigate()

    const handleOpenDialog = (index) => {
        setSelectedIndex(index);
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
        setSelectedIndex(null);
    };


    const handleOpenDialog2 = (index) => {
        setOpen2(true);
    };

    const handleCloseDialog2 = () => {
        setOpen2(false);
    };

    const handleOpenDialog3 = (index) => {
        setOpen3(true);
    };

    const handleCloseDialog3 = () => {
        setOpen3(false);
    };

    const handleOpenDialog4 = (index) => {
        setOpen4(true);
    };

    const handleCloseDialog4 = () => {
        setOpen4(false);
    };



    const handleSelectProduct = (product) => {

        if (selectedIndex !== null) {
            handleInputChange(selectedIndex, product?.name, 'medicine');
            handleInputChange(selectedIndex, product?.id, 'id');
            handleInputChange(selectedIndex, product?.company_id, 'companyid')
            handleInputChange(selectedIndex, product?.deliverycharges, 'deliverycharges')
            handleInputChange(selectedIndex, product?.sku, 'sku')
            handleInputChange(selectedIndex, product?.price, 'price')
            handleInputChange(selectedIndex, product?.desc, 'desc')
            handleInputChange(selectedIndex, product?.qty, 'qty')
            handleInputChange(selectedIndex, product?.imgurl, 'imgurl')
            handleInputChange(selectedIndex, product?.company, "company")
        }

        handleCloseDialog();
    };

    const [acknowledge, setacknowledge] = useState(false)
    const [herbalProducts, setHerbalProducts] = useState([])
    const [Recipes, setRecipes] = useState([])
    const [transcript, setTranscript] = useState('');
    const [includedCategories, setincludedCategories] = useState([])
    const [avoidedCategories, setavoidedCategories] = useState([])
    const [FoodtobeTaken, setFoodtobetaken] = useState([])
    const [Foodtobeavoided, setFoodtobeAvoided] = useState([])
    const [selectedRecipes, setSelectedRecipes] = useState([])
    const [Practices, setPractices] = useState([])
    const [yogalist, setyogalist] = useState(new Map())
    const [meditationList, setMeditationlist] = useState(new Map())
    const [Pranayama, PranayamaList] = useState(new Map())

    const [lifestylechanges, setlifestylechanges] = useState('')

    const [FollowUp, setfollowup] = useState('No')

    const [Medicineinputs, setMedicineinput] = useState([
        { medicine: '', dose: '', instruction: '', duration: '', quantity: 1, id: "" },]
    );

    const [selectedIndex, setSelectedIndex] = useState(null);

    const [Search, setsearch] = useState('')

    const handleAddField = () => {
        setMedicineinput([...Medicineinputs,
        {
            medicine: '', dose: '', instruction: '', duration: '',
            quantity: 1, id: "",
        }]);
    };



    const handleInputChange = (index, inputValue, field) => {
        setMedicineinput((prevInputs) => {
            const newInputs = [...prevInputs];
            newInputs[index][field] = inputValue;
            return newInputs;
        });
    };

    const doctorDetailsCookie = Cookies.get('Doctor_details');
    let Doctor_details = {};

    if (doctorDetailsCookie) {
        try {
            Doctor_details = JSON.parse(doctorDetailsCookie);
        } catch (error) {
            console.log('Error parsing Doctor_details cookie:', error);
        }
    }

    const [Medicaldetails, setMedicaldetails] = useState({
        Complaints: "", lab: "", history: "", diagnosis: "",
    })

    const handleMedicaldetails = (name, value) => {
        setMedicaldetails(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleRemoveField = (index) => {
        const newInputs = Medicineinputs.filter((_, i) => i !== index);
        setMedicineinput(newInputs);
    };

    const getherbalProducts = async () => {
        try {
            let res = await shopModel.get('/getAllproducts')
            if (res.status === 200) {
                setHerbalProducts(res.data)
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const groupByCategory = (items) => {
        return items?.reduce((acc, item) => {
            if (!acc[item.categoryEng]) {
                acc[item.categoryEng] = [];
            }
            acc[item.categoryEng].push(item);
            return acc;
        }, {});
    };


    const getDietRecommendations = async () => {
        try {

            let res = await profileModel.post('/getDietRecommendations', {
                id: selectedPatientdetails.UID
            })

            if (res.status === 200) {
                setincludedCategories(groupByCategory(res.data.Suitable))
                setavoidedCategories(groupByCategory(res.data?.Not_Suitable));
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const getRecipesRecommendations = async () => {
        try {
            let res = await profileModel.post('/getRecipiesRecommendations', {
                id: selectedPatientdetails.UID
            })
            if (res.status === 200) {
                setRecipes(res.data)
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const getlifestylePractices = async () => {
        try {
            let res = await profileModel.get('/getlifestylePractices')
            if (res.status === 200) {
                setPractices(res.data)
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const handleCheckboxChange = (item, isChecked, type) => {
        if (type === 'included') {
            setFoodtobetaken(prevState =>
                isChecked ? [...prevState, item] : prevState.filter(i => i.id !== item.id));
        } else if (type === 'avoided') {
            setFoodtobeAvoided(prevState =>
                isChecked ? [...prevState, item] : prevState.filter(i => i.id !== item.id));
        }
    };

    const handleSelectRecipe = (item, isChecked) => {
        setSelectedRecipes(prevState =>
            isChecked ? [...prevState, item] : prevState.filter(i => i.id !== item.id));
    };

    const AddYogalist = (yogalists) => {
        setyogalist((prev) => {
            let yoga = JSON.parse(yogalists)
            const newlist = new Map(prev)
            if (newlist.has(yoga?.title)) {
                newlist.delete(yoga?.title)
            }
            else {
                newlist.set(yoga.title, yoga)
            }
            return newlist
        })
    }

    const AddMeditationlist = (lists) => {
        setMeditationlist((prev) => {
            let Meditation = JSON.parse(lists)
            const newlist = new Map(prev)
            if (newlist.has(Meditation?.title)) {
                newlist.delete(Meditation?.title)
            }
            else {
                newlist.set(Meditation?.title, Meditation)
            }
            return newlist
        })
    }

    const AddPranayamalist = (lists) => {
        PranayamaList((prev) => {
            let pranayama = JSON.parse(lists)
            const newlist = new Map(prev)
            if (newlist.has(pranayama?.title)) {
                newlist.delete(pranayama?.title)
            }
            else {
                newlist.set(pranayama?.title, pranayama)
            }
            return newlist
        })
    }



    useEffect(() => {
        getherbalProducts()
        getDietRecommendations()
        getlifestylePractices()
        getRecipesRecommendations()
    }, [])

    const Categories = [
        { name: "All", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/all_product_icon.png", type: "All" },
        { name: "Tablets", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/types+of+products/Tablets.png", type: "Tablets" },
        { name: "Powders", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/types+of+products/Powders.png", type: "Churna" },
        { name: "Oils", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/types+of+products/Oils.png", type: "Oil" },
        { name: "Ghee", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/types+of+products/Ghee.png", type: "Ghee" },
        { name: "Decoctions", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/types+of+products/Decoctions.png", type: "Kashayam (Decoctions)" },
        { name: "Fermented", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/types+of+products/Fermented.png", type: "Arishta" },
        { name: "Miscellaneous", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/types+of+products/Miscellaneous.png", type: "Miscellaneous" },
        { name: "Syrups", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/types+of+products/Syrups.png", type: "Syrup" },
        { name: "Tea", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/types+of+products/Tea.png", type: "Tea" }
    ];

    const handlesubmitPrescription = async () => {
        try {
            if (!acknowledge) {
                toast.info("Please check the acknowledgment checkbox before proceeding", {
                    position: "top-center",
                    limit: 1,
                    transition: Zoom,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark"
                })
                return
            }

            setLoading(true);


            let res = await axios.post(`https://f40aygafqj.execute-api.ap-south-1.amazonaws.com/dev/UploadPrecription`, {
                Patient_details: selectedPatientdetails,
                Doctor_details,
                audio: audioBase64?.replace('data:', '')?.replace(/^.+,/, ''),
                Appointment_details: selectedAppointemntdetails,
                Supplements: Medicineinputs,
                Medical_details: Medicaldetails,
                transcript: transcript,
                Diet: {
                    included: FoodtobeTaken,
                    avoided: Foodtobeavoided
                },

                Practices: {
                    yoga: Array.from(yogalist.values()),
                    pranayama: Array.from(Pranayama.values()),
                    meditation: Array.from(meditationList.values())
                },

                Recipes: selectedRecipes,
                LifeStyle_changes: lifestylechanges.split(','),
                Follow_up: FollowUp
            })

            if (res.status === 200) {
                for (const e of Medicineinputs) {
                    if (e.id != '') {
                        const item = {
                            id: e.id,
                            productName: e?.medicine,
                            quantity: parseInt(e.quantity),
                            price: parseInt(e.price),
                            desc: e.desc,
                            companyid: e?.company_id,
                            deliverycharges: e?.deliverycharges,
                            src: e?.imgurl,
                            actualQty: e.qty,
                            sku: e?.sku,
                            company: e?.company
                        }

                        const response = await shopModel.post('/cart', { userId: selectedPatientdetails.UID, item });
                    }
                }
                navigate(`/Precription/${selectedPatientdetails.UID}`)
            }
        }
        catch (error) {
            console.log(error?.response?.data || error)
        }
        finally {
            setLoading(false);
        }
    }

    const handlePatientDetailChanges = (e, field) => {
        const value = e.target.value;
        setSelectedPatientdetails({
            ...selectedPatientdetails,
            [field]: value,
        });
    };

    console.log(Medicineinputs)

    return <div className='UploadPrescription-page'>
        <div className='Prescription-sections'>
            <div className="flex flex-row justify-between items-center w-full">
                <div className='datectn'>{new Date().toDateString()}</div>
                <div>
                    <AudioRecorder onTranscriptChange={setTranscript}
                        onOriginalTranscriptChange={setOriginalTranscript}
                    />
                </div>
            </div>
            <div>

                <div className="audio-transcript-container">
                    {audioBase64 &&
                        <AudioPlayer
                            src={audioBase64}
                            onPlay={(e) => console.log("onPlay")}
                        />
                    }

                    <p>{originalTranscript}</p>

                    <textarea
                        className="transcript-textarea mt-3"
                        value={transcript}
                        onChange={handleTranscriptChange}
                        rows="4"
                    />
                </div>


            </div>
            <div className="flex flex-row justify-between items-center w-full Pdt flex-wrap gap-5" >

                <div className='flex flex-row gap-5 items-center'>
                    <p>Patient name :</p>
                    <input
                        type="text"
                        value={`${selectedPatientdetails?.firstname || ''} ${selectedPatientdetails?.lastname || ''}`}
                        onChange={(e) => {
                            const [first, last] = e.target.value.split(' ');
                            setSelectedPatientdetails({
                                ...selectedPatientdetails,
                                firstname: first || '',
                                lastname: last || '',
                            });
                        }}
                        className="border p-1 w"
                    />
                </div>
                <div className='flex flex-row gap-5 items-center'>
                    <p>Age :</p>
                    <input
                        type="number"
                        value={selectedPatientdetails?.dob}
                        onChange={(e) => handlePatientDetailChanges(e, 'dob')}
                        className="border p-1 w"
                    />
                </div>
                <div className='flex flex-row gap-5 items-center'>
                    <p>Gender :</p>
                    <select
                        value={selectedPatientdetails?.gender || ''}
                        onChange={(e) => handlePatientDetailChanges(e, 'gender')}
                        className="border p-1 w"
                    >
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
                <div className='flex flex-row gap-5 items-center'>
                    <p>Height :</p>
                    <input
                        type="text"
                        value={selectedPatientdetails?.height || ''}
                        onChange={(e) => handlePatientDetailChanges(e, 'height')}
                        className="border p-1 w"
                    />
                </div>
                <div className='flex flex-row gap-5 items-center'>
                    <p>Weight :</p>
                    <input
                        type="text"
                        value={selectedPatientdetails?.weight || ''}
                        onChange={(e) => handlePatientDetailChanges(e, 'weight')}
                        className="border p-1 w-50"
                    />
                </div>

            </div>
            <div >
                <p className='Prescription-sh-h'>Medical Details</p>
                <div className='Medical-details'>
                    <TextField
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '&.Mui-focused fieldset': {
                                    border: 'none',
                                },
                            },
                            '& .MuiInputBase-root': {
                                '&:focus': {
                                    outline: 'none',
                                },
                            },
                        }}
                        id='outlined-basic'
                        label='Complaints'

                        onChange={(e) => {
                            handleMedicaldetails('Complaints', e.target.value)
                        }}

                        variant='outlined'
                    />
                    <TextField
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '&.Mui-focused fieldset': {
                                    border: 'none',
                                },
                            },
                            '& .MuiInputBase-root': {
                                '&:focus': {
                                    outline: 'none',
                                },
                            },
                        }}
                        id='outlined-basic'
                        label='Examination / Lab findings'

                        onChange={(e) => {
                            handleMedicaldetails('lab', e.target.value)
                        }}

                        variant='outlined'
                    />
                    <TextField
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '&.Mui-focused fieldset': {
                                    border: 'none',
                                },
                            },
                            '& .MuiInputBase-root': {
                                '&:focus': {
                                    outline: 'none',
                                },
                            },
                        }}
                        id='outlined-basic'
                        label='Medical History'

                        onChange={(e) => {
                            handleMedicaldetails("history", e.target.value)
                        }}

                        variant='outlined'
                    />
                    <TextField
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '&.Mui-focused fieldset': {
                                    border: 'none',
                                },
                            },
                            '& .MuiInputBase-root': {
                                '&:focus': {
                                    outline: 'none',
                                },
                            },
                        }}
                        id='outlined-basic'
                        label='Diagnosis'

                        onChange={(e) => {
                            handleMedicaldetails('diagnosis', e.target.value)
                        }}

                        variant='outlined'
                    />

                </div>
            </div>
            <div >
                <p className='Prescription-sh-h'>Herbal Supplemnents</p>
                <div>
                    {Medicineinputs.map((input, index) => (
                        <div key={index} className='flex flex-row gap-5 items-center'>
                            <div className='Medical-details mt-5'>
                                <TextField
                                    className='medicine-input'
                                    label="Medicine Name"
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                border: 'none',
                                            },
                                        },
                                        '& .MuiInputBase-root': {
                                            '&:focus': {
                                                outline: 'none',
                                            },
                                        },
                                    }}
                                    id='outlined-basic'
                                    InputProps={{
                                        endAdornment: (
                                            !input.medicine ? (
                                                <InputAdornment position="end">
                                                    <IconButton edge="end" size="small" onClick={() => handleOpenDialog(index)}>
                                                        <AddIcon fontSize='10px' />
                                                    </IconButton>
                                                </InputAdornment>
                                            ) : null
                                        ),
                                    }}
                                    value={input.medicine}
                                    onChange={(event) => handleInputChange(index, event.target.value, "medicine")}
                                    variant="outlined"
                                />

                                <TextField
                                    label="Quantity"
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                border: 'none',
                                            },
                                        },
                                        '& .MuiInputBase-root': {
                                            '&:focus': {
                                                outline: 'none',
                                            },
                                        },
                                    }}
                                    id='outlined-basic'
                                    className='medicine-input2'
                                    value={input.quantity}
                                    onChange={(event) => handleInputChange(index, event.target.value, "quantity")}
                                    variant="outlined"
                                />
                                <TextField
                                    className='medicine-input3'
                                    label="Dose"
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                border: 'none',
                                            },
                                        },
                                        '& .MuiInputBase-root': {
                                            '&:focus': {
                                                outline: 'none',
                                            },
                                        },
                                    }}
                                    id='outlined-basic'
                                    value={input.dose}
                                    onChange={(event) => handleInputChange(index, event.target.value, "dose")}
                                    variant="outlined"
                                />
                                <TextField
                                    label="Duration"
                                    className='medicine-input4'
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                border: 'none',
                                            },
                                        },
                                        '& .MuiInputBase-root': {
                                            '&:focus': {
                                                outline: 'none',
                                            },
                                        },
                                    }}
                                    id='outlined-basic'
                                    value={input.duration}
                                    onChange={(event) => handleInputChange(index, event.target.value, "duration")}
                                    variant="outlined"
                                />
                                <TextField
                                    label="Instruction"
                                    className='medicine-input5'
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                border: 'none',
                                            },
                                        },
                                        '& .MuiInputBase-root': {
                                            '&:focus': {
                                                outline: 'none',
                                            },
                                        },
                                    }}
                                    id='outlined-basic'
                                    value={input.instruction}
                                    onChange={(event) => handleInputChange(index, event.target.value, "instruction")}
                                    variant="outlined"
                                />
                            </div>
                            <div style={index > 0 ? { visibility: "visible" } : { visibility: 'hidden' }}>
                                <IconButton>
                                    <HighlightOffIcon
                                        onClick={() => handleRemoveField(index)}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </IconButton>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='add-more' onClick={handleAddField}>
                    Add more <AddCircleOutlineIcon />
                </div>
            </div>
            <div>
                <p className='Prescription-sh-h'>Diet Recommendations</p>
                <div className='DietRecommendation-add flex flex-row justify-between mt-2 gap-3'>
                    <div className='dietrc'>
                        <p>Food to be included</p>
                        <div className='D_dietc'>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                <IconButton onClick={handleOpenDialog2}>
                                    <AddIcon />
                                </IconButton>
                            </div>
                            <div>
                                <ul className="FoodtobeTaken">
                                    {
                                        FoodtobeTaken.map((e) => {
                                            return <li>{e.englishName}</li>
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='dietrc'>
                        <p>Food to be avoided</p>
                        <div className='D_dietc'>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                <IconButton onClick={handleOpenDialog3}>
                                    <AddIcon />
                                </IconButton>
                            </div>
                            <div>
                                <ul className="FoodtobeTaken">
                                    {
                                        Foodtobeavoided.map((e) => {
                                            return <li>{e.englishName}</li>
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <p className='Prescription-sh-h'>Recipes</p>
                <div className='dietrc'>
                    <div className='D_dietc'>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                            <IconButton onClick={handleOpenDialog4}>
                                <AddIcon />
                            </IconButton>
                        </div>
                        <div>
                            <ul className="FoodtobeTaken">
                                {
                                    selectedRecipes.map((e) => {
                                        return <li>{e.recipe}</li>
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <p className='Prescription-sh-h'>
                    Lifestyle Practices
                </p>
                <div className='DietRecommendation-add flex flex-row justify-between mt-2 gap-3'>

                    <div className='practices-card'>
                        <p>Yoga</p>
                        <select onChange={(e) => AddYogalist(e.target.value)}>
                            {
                                Practices?.yoga?.map((e) => {
                                    return <option value={JSON.stringify(e)}>{e?.title}</option>
                                })
                            }
                        </select>
                        <ul>
                            {
                                Array.from(yogalist.values()).map((e) => {
                                    return <li>{e?.title}</li>
                                })
                            }
                        </ul>

                    </div>

                    <div className='practices-card'>
                        <p>Pranayama</p>
                        <select onChange={(e) => AddPranayamalist(e.target.value)}>
                            {
                                Practices?.pranayama?.map((e) => {
                                    return <option value={JSON.stringify(e)}>{e?.title}</option>
                                })
                            }
                        </select>
                        <ul>
                            {
                                Array.from(Pranayama.values()).map((e) => {
                                    return <li>{e?.title}</li>
                                })
                            }
                        </ul>
                    </div>

                    <div className='practices-card'>
                        <p>Meditation</p>
                        <select onChange={(e) => AddMeditationlist(e.target.value)}>
                            {
                                Practices?.meditation?.map((e) => {
                                    return <option value={JSON.stringify(e)}>{e?.title}</option>
                                })
                            }
                        </select>
                        <ul>
                            {
                                Array.from(meditationList.values()).map((e) => {
                                    return <li>{e?.title}</li>
                                })
                            }
                        </ul>
                    </div>

                </div>
            </div>
            <div>
                <p className='Prescription-sh-h'>
                    Lifestyle Changes
                </p>
                <textarea className='w-full mt-3 lifestylechanges' rows="5" style={{ resize: "none" }}
                    onChange={(e) => {
                        setlifestylechanges(e.target.value)
                    }}>
                </textarea>
            </div>
            <div>
                <p className='Prescription-sh-h'>
                    Follow up
                </p>
                <input type='date' onChange={(e) => {
                    setfollowup(e.target.value.split('-').reverse().join('-'))
                }}>
                </input>
            </div>
            <div>
                <div className='formbtn'>
                    <div className='checkbox'>
                        <input type='checkbox' onChange={(e) => {
                            setacknowledge(e?.target?.checked)
                        }}></input>
                        <p>I, {Doctor_details?.name}, acknowledge the submission of the prescription form for the patient named {selectedPatientdetails?.firstname + " " + selectedPatientdetails?.lastname} on {new Date().toString()}, and confirm the review of all relevant patient details and prescribed medications.</p>
                    </div>
                </div>
            </div>
            <div style={{ width: "10rem", margin: "auto" }}>
                <Button
                    style={{
                        width: '100%',
                        textTransform: 'none',
                        borderRadius: '0',
                        backgroundColor: '#DA6E0B',
                        color: 'white',
                        border: 'none',
                        boxSizing: 'border-box',
                        borderRadius: "5px",
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 500,
                        padding: "10px"
                    }}

                    onClick={handlesubmitPrescription}
                >
                    {loading ? <CircularProgress size={24} /> : 'Submit'}
                </Button>
            </div>
        </div>
        <Dialog
            fullScreen
            open={open}
            onClose={handleCloseDialog}
        >
            <div className='Herbal-products-container'>
                <p className='Prescription-sh-h'>Herbal Products</p>
                <div className='Search-bar'>
                    <div className='search-box'>
                        <img src='/icons/search icon 1.png'></img>
                        <input type="text" placeholder="Search" onChange={(e) => { setsearch(e.target.value) }} />
                    </div>
                </div>
                <div className='ListofCategories'>
                    {Categories.map((e) => {
                        return (
                            <div onClick={() => {
                                if (e?.type?.toLowerCase()?.includes(Search?.toLowerCase())) {
                                    setsearch('all')
                                }
                                else {
                                    setsearch(e.type)
                                }
                            }}>
                                <img src={e.img}></img>
                                <p className={e?.type?.toLowerCase()?.includes(Search?.toLowerCase()) && Search != '' ? 'lineactive' : ""}>{e.name}</p>
                            </div>
                        )

                    })}
                </div>
                <div className="herbal-products">
                    {herbalProducts.filter((s) => {
                        if (Search === '') {
                            return s
                        }
                        if (Search === 'all') {
                            return s
                        }
                        else if (s?.name?.toLowerCase()?.includes(Search?.toLowerCase()) || s?.ProductType?.toLowerCase()?.includes(Search?.toLowerCase())) {
                            return s
                        }
                    }).map((e) => {
                        return (
                            <div key={e?.id} className="herbal-product-card">
                                <div className="img-container">
                                    <img src={e?.imgurl} alt={e?.name} />
                                    <input type="checkbox" className="overlay-checkbox"
                                        onChange={() => handleSelectProduct(e)} />
                                </div>
                                <p>{e?.name}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
        </Dialog>

        <Dialog
            open={open2}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseDialog2}
            aria-describedby="alert-dialog-slide-description"
        >
            <div className='Herbal-products-container'>
                <div className='flex flex-row justify-between items-center mb-3'>
                    <p className='Prescription-sh-h'>Diet</p>
                    <div className='flex flex-row justify-center items-center gap-2'>
                        <p className='Prescription-sh-h'>Food to be included</p>
                        <div style={{ width: "20px", height: "20px", backgroundColor: "#7FD67F", borderRadius: "3px" }}>
                        </div>
                    </div>
                </div>

                {Object?.keys(includedCategories)?.map((category, index) => (
                    <div key={index} className="category">
                        <h3>{category}</h3>
                        <div className="item-grid">
                            {includedCategories[category]?.map(item => (
                                <div className="food-item">
                                    <div className='food-item-img-container mb-2'>
                                        <img src={item?.imageLink} alt={item?.englishName} className="food-image" />
                                        <input type="checkbox" className="overlay-checkbox" style={{ width: "auto", height: "auto", top: "5px", right: "8px" }}

                                            onChange={(e) => {
                                                handleCheckboxChange(item, e.target.checked, "included")
                                            }} />

                                    </div>
                                    <p className='mt-5'>{item?.englishName}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </Dialog>

        <Dialog
            open={open3}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseDialog3}
            aria-describedby="alert-dialog-slide-description"
        >
            <div className='Herbal-products-container'>
                <div className='flex flex-row justify-between items-center mb-3'>
                    <p className='Prescription-sh-h'>Diet</p>
                    <div className='flex flex-row justify-center items-center gap-2'>
                        <p className='Prescription-sh-h'>Food to be avoided</p>
                        <div style={{ width: "20px", height: "20px", backgroundColor: "#F15050", borderRadius: "3px" }}>
                        </div>
                    </div>
                </div>

                {Object?.keys(avoidedCategories)?.map((category, index) => (
                    <div key={index} className="category">
                        <h3>{category}</h3>
                        <div className="item-grid">
                            {avoidedCategories[category]?.map(item => (

                                <div className="food-item">
                                    <div className='food-item-img-container mb-2'>
                                        <img src={item?.imageLink} alt={item?.englishName} className="food-image" />
                                        <input type="checkbox" className="overlay-checkbox" style={{ width: "auto", height: "auto", top: "5px", right: "8px" }}
                                            onChange={(e) => {
                                                handleCheckboxChange(item, e.target.checked, "avoided")
                                            }} />
                                    </div>
                                    <p className='mt-5'>{item?.englishName}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </Dialog>

        <Dialog
            open={open3}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseDialog3}
            aria-describedby="alert-dialog-slide-description"
        >
            <div className='Herbal-products-container'>
                <div className='flex flex-row justify-between items-center mb-3'>
                    <p className='Prescription-sh-h'>Diet</p>
                    <div className='flex flex-row justify-center items-center gap-2'>
                        <p className='Prescription-sh-h'>Food to be avoided</p>
                        <div style={{ width: "20px", height: "20px", backgroundColor: "#F15050", borderRadius: "3px" }}>
                        </div>
                    </div>
                </div>

                {Object?.keys(avoidedCategories)?.map((category, index) => (
                    <div key={index} className="category">
                        <h3>{category}</h3>
                        <div className="item-grid">
                            {avoidedCategories[category]?.map(item => (

                                <div className="food-item">
                                    <div className='food-item-img-container mb-2'>
                                        <img src={item?.imageLink} alt={item?.englishName} className="food-image" />
                                        <input type="checkbox" className="overlay-checkbox" style={{ width: "auto", height: "auto", top: "5px", right: "8px" }}
                                            onChange={(e) => {
                                                handleCheckboxChange(item, e.target.checked, "avoided")
                                            }} />
                                    </div>
                                    <p className='mt-5'>{item?.englishName}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </Dialog>

        <Dialog
            open={open4}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseDialog4}
            aria-describedby="alert-dialog-slide-description"
        >
            <div className='Herbal-products-container'>
                <div className='flex flex-row justify-between items-center mb-3'>
                    <p className='Prescription-sh-h'>Recipes</p>
                </div>
                <div className="item-grid justify-center">
                    {Recipes?.map(item => (
                        <div className="food-item">
                            <div className='food-item-img-container mb-2'>
                                <img src={item?.imageLink} alt={item?.recipe} className="food-image" />
                                <input type="checkbox" className="overlay-checkbox" style={{ width: "auto", height: "auto", top: "5px", right: "8px" }}
                                    onChange={(e) => {
                                        handleSelectRecipe(item, e.target.checked)
                                    }} />
                            </div>
                            <p className="mt-5 w-full" style={{ wordWrap: "break-word", width: "10rem" }}>{item?.recipe}</p>
                        </div>
                    ))}
                </div>
            </div>
        </Dialog>

    </div>
}

export default UploadPrescription