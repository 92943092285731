import React from 'react'
import useReportStore from '../DataRepository/Reports'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

const initBeforeUnLoad = (showExitPrompt) => {
    window.onbeforeunload = (event) => {
        if (showExitPrompt) {
            const e = event || window.event;
            e.preventDefault();
            if (e) {
                e.returnValue = "";
            }
            return "";
        }
    };
};



function ViewReportList() {
    const { selectedReport , setSelectedReportUrl} = useReportStore()

    const [showExitPrompt, setShowExitPrompt] = useState(true);

    const Navigate = useNavigate()

    window.onload = function () {
        initBeforeUnLoad(showExitPrompt);
    };

    useEffect(() => {
        initBeforeUnLoad(showExitPrompt);
        return () => {
            window.onbeforeunload = null;
        };
    }, [showExitPrompt]);





    return <div className='Reports-main-page'>
        <div className='Reports-text-box'>
            <p>{selectedReport?.ReportType}</p>
            <div className='di-selectedReportscards'>
                {
                    selectedReport?.Reports.map((e, i) => {
                        return <div className='SelectedReportCard' key={i} style={{ cursor: "pointer" }}
                            onClick={() => {
                                setSelectedReportUrl(e?.Location)
                                Navigate('/ViewReport')
                            }}>
                            {selectedReport.filetype === 'images' ? (
                                <img src={e.Location} alt='Image icon' style={{ alignSelf: "center", justifySelf: "center" }} />
                            ) : (
                                <img src='/icons/pdf icon.png' alt='PDF icon' />
                            )}
                            <p className='text-center mt-auto mb-2'>{e.Key.split('/')[3]}</p>
                        </div>
                    })
                }
            </div>
        </div>

    </div>
}

export default ViewReportList