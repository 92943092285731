import React from 'react'
import usePrescriptionStore from '../DataRepository/Prescription';
import { IconButton } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { useEffect, useState, useRef } from 'react'

const initBeforeUnLoad = (showExitPrompt) => {
    window.onbeforeunload = (event) => {
        if (showExitPrompt) {
            const e = event || window.event;
            e.preventDefault();
            if (e) {
                e.returnValue = "";
            }
            return "";
        }
    };
};

function ViewPrescription() {

    const { selectedPrescription } = usePrescriptionStore()

    const [showExitPrompt, setShowExitPrompt] = useState(true);

    window.onload = function () {
        initBeforeUnLoad(showExitPrompt);
    };

    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlayPause = () => {
        const audio = audioRef.current;
        if (isPlaying) {
            audio.pause();
        } else {
            audio.play();
        }
        setIsPlaying(!isPlaying);
    };

    useEffect(() => {
        initBeforeUnLoad(showExitPrompt);
        return () => {
            window.onbeforeunload = null;
        };
    }, [showExitPrompt]);

    return <div className='Reports-main-page'>
        <div className='Reports-text-box'>
            <div className='flex flex-row justify-between item-center m-2'>
                <p>{selectedPrescription?.Date}</p>
                <div>
                    <audio ref={audioRef}>
                        <source src={selectedPrescription?.Audio_Prescription} type="audio/mpeg" />
                        Your browser does not support the audio element.
                    </audio>
                    <IconButton onClick={handlePlayPause} style={{ color: "#DA6E0B" }}>
                        {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                    </IconButton>
                    <p>Audio Prescription</p>
                </div>
            </div>

            <div className='flex flex-row justify-center align-items-center display-report'  >
                <iframe
                    src={`${selectedPrescription?.Prescription}?page=hsn#toolbar=0`}
                    width={"100%"}
                    height={"100%"}

                ></iframe>
            </div>
        </div>

    </div>
}

export default ViewPrescription