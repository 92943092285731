import React, { useState, useEffect } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/system';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import axios from 'axios';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Cookies from 'js-cookie';
import { Link, useNavigate } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';

const CalendarContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
});

const DayBox = styled(Box)(({ theme, isSelected }) => ({
    padding: theme.spacing(2),
    backgroundColor: isSelected ? "#DA6E0B" : '#F1EFEC',
    color: isSelected ? '#fff' : '#000',
    borderRadius: '4px',
    textAlign: 'center',
    cursor: 'pointer',
    margin: '0 5px',
}));


const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    borderRadius: '10px',
    marginRight: '1rem',
    overflow: "auto",
    margin: "2rem",
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
    backgroundColor: '#E7E7DB',
    borderRadius: '10px',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        // backgroundColor: theme.palette.action.hover,
    },
}));

const TimingCell = styled(TableCell)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    gap: '8px',
}));

const TimingButton = styled(Button)(({ theme }) => ({
    minWidth: '32px',
    height: '32px',
    // borderRadius: '50%',

    backgroundColor: '#C0B7A7',
    color: 'white',

}));

const ModeCell = styled(TableCell)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

const ModeButton = styled(Button)(({ theme }) => ({
    marginLeft: '8px',
}));

const Appointments = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [currentdate, setcurrentdate] = useState(new Date());
    const [consultationData, setconsultationData] = useState([]);
    const Navigate = useNavigate()

    const getWeekDays = (date) => {
        const startOfWeek = new Date(date);
        startOfWeek.setDate(date.getDate() - date.getDay());
        const days = [];
       
        for (let i = 0; i < 7; i++) {
            days.push(new Date(startOfWeek));
            startOfWeek.setDate(startOfWeek.getDate() + 1);
        }
        return days;
    };

    const doctorDetailsCookie = Cookies.get('Doctor_details');
    let Doctor_details = {};

    if (doctorDetailsCookie) {
        try {
            Doctor_details = JSON.parse(doctorDetailsCookie);
        } catch (error) {
            console.log('Error parsing Doctor_details cookie:', error);
        }
    }

    const handleDateClick = (date) => {
        setSelectedDate(date);
        setcurrentdate(date)
    };

    const handlePreviousWeek = () => {
        const newDate = new Date(selectedDate);
        newDate.setDate(selectedDate.getDate() - 7);
        setSelectedDate(newDate);
    };

    const handleNextWeek = () => {
        const newDate = new Date(selectedDate);
        newDate.setDate(selectedDate.getDate() + 7);
        setSelectedDate(newDate);
    };

    const weekDays = getWeekDays(selectedDate);

    const getAppointmentData = async () => {
        try {

            let res = await axios.post('https://z2t1vcmobj.execute-api.ap-south-1.amazonaws.com/dev/getAppointmentbyDate', {
                Date: selectedDate.toISOString().split('T')[0],
                Doc_id: Doctor_details.UID
            })

            if (res.status == 200) {
                setconsultationData(res.data)
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getAppointmentData()
    }, [selectedDate])

    return (
        <div className='Appointments-page'>

            <div className='Appointment-selection-container'>
                <div className='Today-appointment'>
                    <div className='Today-appointment-title'>
                        <p>Today's Appointments</p>
                        <img src='./icons/todayappointmenticon.png' />
                    </div>
                    <div className='Appointment-count'>
                        {consultationData?.length}
                    </div>
                </div>
                <div className='Appointment-selector'>
                    <div className='Appointment-picker'>
                        <div>
                            <p>{selectedDate.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</p>
                        </div>
                        <div>
                            <IconButton><ArrowBackIosIcon onClick={handlePreviousWeek} style={{ cursor: 'pointer' }} /></IconButton>
                            <IconButton><ArrowForwardIosIcon onClick={handleNextWeek} style={{ cursor: 'pointer' }} /></IconButton>
                        </div>
                    </div>
                    <CalendarContainer>

                        {weekDays.map((date, index) => (
                            <DayBox
                                key={index}
                                isSelected={currentdate.toDateString() === date.toDateString()}
                                onClick={() => handleDateClick(date)}
                            >
                                <Typography variant="body2">{date.toLocaleDateString('en-US', { weekday: 'short' })}</Typography>
                                <Typography variant="body1">{date.getDate()}</Typography>
                            </DayBox>
                        ))}

                    </CalendarContainer>
                </div>
            </div>

            <div className='list-appointments'>
                <TableContainer style={{ borderRadius: "8px" }} className='AppointmentTable'>
                    <Table>
                        <StyledTableHead>
                            <TableRow >
                                <TableCell className='tableheadtitle' align='center'>Timing</TableCell>
                                <TableCell className='tableheadtitle' align='center'>Patient Name</TableCell>
                                <TableCell className='tableheadtitle' align='center'>Gender</TableCell>
                                <TableCell className='tableheadtitle' align='center'>Age</TableCell>
                                <TableCell className='tableheadtitle' align='center'>Mode of Consultation</TableCell>
                                <TableCell className='tableheadtitle'></TableCell>
                            </TableRow>
                        </StyledTableHead>
                        <TableBody>
                            {consultationData.map((row, index) => (
                                <TableRow key={index} className='TableRowContainer'>
                                    <TableCell align='center'>
                                        {row.appointment_time}
                                    </TableCell>
                                    <TableCell align='center'>
                                        <TimingButton>{row?.patient_name?.charAt(0)}</TimingButton> {row?.patient_name}</TableCell>
                                    <TableCell align='center'>{row?.gender || "Male"}</TableCell>
                                    <TableCell align='center'>{row?.patient_age}</TableCell>
                                    <TableCell align='center'>
                                        {row?.consultation_type} Consultation

                                    </TableCell>
                                    <TableCell align='right'>
                                        <IconButton component={Link} to={`/Patient/${row.Appointment_id}`}>
                                            <ChevronRightIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>


            </div>

        </div>
    );
};

export default Appointments;
