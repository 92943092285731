import React, { useEffect, useState, useRef } from "react";
import { ZIM } from "zego-zim-web";
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import Cookies from "js-cookie";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

// Function to generate a random ID
function randomID(len) {
    let result = "";
    const chars = "12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP";
    const maxPos = chars.length;
    for (let i = 0; i < len; i++) {
        result += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return result;
}

function debounce(func, delay) {
    let debounceTimeout;
    return function (...args) {
        clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(() => func.apply(this, args), delay);
    };
}

export default function Call() {
    const [userInfo, setUserInfo] = useState({
        userName: "",
        userId: "",
    });

    const navigate = useNavigate();
    const { id, type } = useParams();

    const videoCallDivRef = useRef(null);
    const [calleeId, setCalleeId] = useState(id.split('-')[0]);
    const [callActive, setCallActive] = useState(false);
    const [callRejected, setCallRejected] = useState(false);
    const zegoCloudInstance = useRef(null);

    const doctorDetailsCookie = Cookies.get('Doctor_details');
    let Doctor_details = {};

    if (doctorDetailsCookie) {
        try {
            Doctor_details = JSON.parse(doctorDetailsCookie);
        } catch (error) {
            console.log('Error parsing Doctor_details cookie:', error);
        }
    }

    async function init() {
        const userId = Doctor_details.UID.split('-')[0];
        const userName = Doctor_details?.name;
        
        setUserInfo({
            userName,
            userId,
        });

        const appID = 197553733;
        const serverSecret = "0fbade5cc539bd4335b2336f0c75baf4";
        const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
            appID,
            serverSecret,
            null,
            userId,
            userName
        );

        zegoCloudInstance.current = ZegoUIKitPrebuilt.create(kitToken);
        zegoCloudInstance.current.addPlugins({ ZIM });

        // Automatically invoke the call based on the call type from useParams
        debouncedHandleSend(type === "Video" ? ZegoUIKitPrebuilt.InvitationTypeVideoCall : ZegoUIKitPrebuilt.InvitationTypeVoiceCall);
    }

    const debounceDelay = 1000; // 1 second delay

    const debouncedHandleSend = debounce((callType) => {
        const callee = calleeId;
        if (!callee) {
            alert("User ID cannot be empty!");
            return;
        }

        zegoCloudInstance.current.setCallInvitationConfig({
            ringtoneConfig: {
             incomingCallUrl: 'https://aiwellassets.s3.ap-south-1.amazonaws.com/in-y2mate.com+-+Space+Guitar+Bliss+Ringtone.mp3', 
           }
         })
         
        zegoCloudInstance.current
            .sendCallInvitation({
                callees: [{ userID: callee, userName: "user_" + callee }],
                callType: callType,
                timeout: 60,
                onCallInvitationEnded:()=>{
                    setCallActive(false); 
                }
            })
            .then((res) => {
                console.warn(res);
                if (res.errorInvitees.length) {
                    alert("The user does not exist or is offline.");
                } else {
                    zegoCloudInstance.current.joinRoom({
                        container: videoCallDivRef.current,
                        scenario: {
                            mode: ZegoUIKitPrebuilt.OneONoneCall,
                        },
                        onLeaveRoom: () => {
                            setCallActive(false);
                        },
                    });
                    setCallActive(true);
                    setCallRejected(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, debounceDelay);

    useEffect(() => {
        init();
    }, []);

    return (
        <div className='Patient_Profile_list_container' style={{ flexDirection: "row-reverse" }}>
            {callActive && <div className='Patient-info'>
                <div className='Patient-card'>
                    <div className='Patient-info-details'></div>
                </div>
                <div></div>
                <div></div>
            </div>}

            <div className='Patient-more-info mr-5'>
                <div className='Patient-appointment-details mr-5'>
                    <div ref={videoCallDivRef} style={callActive ? { width: "100%", height: '85vh', borderRadius: "8px" } : { display: "none" }}></div>
                    <div className="flex flex-col justify-evenly call-disconnected"
                        style={!callActive ? { width: "100%", height: '85vh', borderRadius: "8px" } : { display: "none" }}>
                        <img src='/icons/call disconnected.png' alt="Call Disconnected"></img>
                        <div className="flex flex-row justify-center gap-5">
                            <div style={{ width: "15rem" }}>
                                <Button
                                    style={{
                                        width: '100%',
                                        textTransform: 'none',
                                        borderRadius: '0',
                                        border: "1px solid #DA6E0B ",
                                        color: '#DA6E0B', /* Text color for better contrast */
                                        boxSizing: 'border-box',
                                        borderRadius: "5px",
                                        fontFamily: "'Poppins', sans-serif",
                                        fontWeight: 500,
                                        padding: "10px"
                                    }}
                                    onClick={() => {
                                        setCallActive(false);
                                        navigate(-1);
                                    }}
                                >
                                    Finish
                                </Button>
                            </div>
                            <div style={{ width: "15rem" }}>
                                <Button
                                    style={{
                                        width: '100%',
                                        textTransform: 'none',
                                        borderRadius: '0',
                                        backgroundColor: '#DA6E0B',
                                        color: 'white',
                                        border: 'none',
                                        boxSizing: 'border-box',
                                        borderRadius: "5px",
                                        fontFamily: "'Poppins', sans-serif",
                                        fontWeight: 500,
                                        padding: "10px"
                                    }}

                                    onClick={() => {
                                        debouncedHandleSend(type === "Video" ? ZegoUIKitPrebuilt.InvitationTypeVideoCall : ZegoUIKitPrebuilt.InvitationTypeVoiceCall);
                                    }}
                                >
                                    Rejoin
                                </Button>
                            </div>
                        </div>
                        {callRejected && <p>The callee has rejected the call.</p>}
                    </div>
                </div>
            </div>
        </div>
    );
}
