import React from 'react'
import { Button } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import useReportStore from '../DataRepository/Reports';
import { IconButton } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Reports() {
    const [open, setOpen] = useState(false);
    const { id } = useParams()
    const [reports, setReports] = useState([])
    const { setSelectedReport } = useReportStore();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const navigate = useNavigate()

    const doctorDetailsCookie = Cookies.get('Doctor_details');
    let Doctor_details = {};

    if (doctorDetailsCookie) {
        try {
            Doctor_details = JSON.parse(doctorDetailsCookie);
        } catch (error) {
            console.log('Error parsing Doctor_details cookie:', error);
        }
    }

    const getReports = async () => {
        try {
            const res = await axios.get(`https://mcqncj4lj7.execute-api.ap-south-1.amazonaws.com/dev/GetReports/${id}`)
            if (res.status == 200) {
                setReports(res.data)
            }
        }

        catch (err) {
            console.log(err)
        }
    }

    const handleDelete = (index) => {
        setReports((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    useEffect(() => {
        getReports()
    }, [])

    const handleReportClick = (report) => {
        setSelectedReport(report);
        navigate('/ViewReportList')
    };

    return <div className='Reports-main-page'>
        <div className='Reports-text-box'>
            <p>Reports</p>
        </div>

        {reports.length > 0 ?
            <><div className='ListReports'>
                {reports.map((e, i) => {
                    return <div className="ListReportCard" onClick={() => handleReportClick(e)}>
                        <IconButton style={{ alignSelf: "flex-start" }} onClick={() => handleDelete(i)}>
                            <DeleteIcon fontSize='small' />
                        </IconButton>
                        <div>
                            <p>{e.ReportType}</p>
                            <p>{e.ReportUploadedby === Doctor_details.UID
                                ? "Uploaded by you" : "Uploaded by patient"}</p>
                            <p>{e.CreatedAt.split("GMT")[0]}</p>
                        </div>
                        <div>
                            {e.filetype === 'images' ? (
                                <img src='/icons/Image icon-1.png' alt='Image icon' />
                            ) : (
                                <img src='/icons/pdf icon.png' alt='PDF icon' />
                            )}
                        </div>
                    </div>
                })
                }
            </div>
                <div className='uploadReportbtn'>
                    <Button
                        style={{
                            width: '100%',
                            textTransform: 'none',
                            borderRadius: '0',
                            backgroundColor: '#DA6E0B',
                            color: 'white',
                            border: 'none',
                            boxSizing: 'border-box',
                            borderRadius: "5px",
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: 500,
                            padding: "10px"
                        }}
                        onClick={handleClickOpen}
                    >
                        Add a report
                    </Button>
                </div>
            </>
            :
            <div className='No-reports'>
                <img src='/icons/No report image.png'></img>
                <p>No health reports found</p>
                <p>Please upload the patient's report to assist us in maintaining accurate and comprehensive patient records.</p>
                <div>
                    <Button
                        style={{
                            width: '100%',
                            textTransform: 'none',
                            borderRadius: '0',
                            border: "1px solid #DA6E0B ",
                            color: '#DA6E0B',
                            boxSizing: 'border-box',
                            borderRadius: "5px",
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: 500,
                            padding: "10px"
                        }}
                        onClick={() => {
                            handleClickOpen()
                        }}
                    >
                        Add a report
                    </Button>
                </div>
            </div>
        }

        <Dialog

            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogContent id="dialog">
                <div className='dialog-content'>
                    <p>Select your report type</p>
                    <div className='dialog-action-items'>

                        <div>
                            <Button
                                style={{
                                    width: '100%',
                                    textTransform: 'none',
                                    borderRadius: '0',
                                    border: "1px solid #3B362A",
                                    backgroundColor: "#EFE3C2",
                                    color: '#3B362A',
                                    boxSizing: 'border-box',
                                    borderRadius: "5px",
                                    fontFamily: "'Poppins', sans-serif",
                                    fontWeight: 500,
                                    padding: "10px"
                                }}

                                onClick={() => {
                                    navigate(`/UploadReports/${id}/images`)
                                }}
                            >
                                <div className='btn-content'>
                                    <img src='/icons/Image icon.png'></img>
                                    <p>Images</p>
                                </div>
                            </Button>
                        </div>

                        <div>
                            <Button
                                style={{
                                    width: '100%',
                                    textTransform: 'none',
                                    borderRadius: '0',
                                    border: "1px solid #3B362A",
                                    backgroundColor: "#EFE3C2",
                                    color: '#3B362A',
                                    boxSizing: 'border-box',
                                    borderRadius: "5px",
                                    fontFamily: "'Poppins', sans-serif",
                                    fontWeight: 500,
                                    padding: "10px"
                                }}

                                onClick={() => {
                                    navigate(`/UploadReports/${id}/Document`)
                                }}
                            >
                                <div className='btn-content'>
                                    <img src='/icons/pdf icon.png'></img>
                                    <p>Document</p>
                                </div>
                            </Button>
                        </div>

                    </div>
                </div>
            </DialogContent>

        </Dialog>
    </div>
}

export default Reports