import React, { useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import { IconButton } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import Box from "@mui/material/Box";

let currentPlayingWaveSurfer = null; // Global reference to the currently playing WaveSurfer instance

const AudioWave = ({ audioUrl }) => {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    wavesurfer.current = WaveSurfer.create({
      container: waveformRef.current,
      waveColor: "#D4CEC3",
      progressColor: "#DA6E0B",
      cursorColor: "#333",
      height: 100,
      barWidth: 2,
      responsive: true,
      normalize: true,
    });

    wavesurfer.current.load(audioUrl);

    wavesurfer.current.on("finish", () => setIsPlaying(false));

    return () => {
      wavesurfer.current.destroy();
    };
  }, [audioUrl]);

  const handlePlayPause = () => {
    if (isPlaying) {
      wavesurfer.current.pause();
      setIsPlaying(false);
    } else {
      if (currentPlayingWaveSurfer && currentPlayingWaveSurfer !== wavesurfer.current) {
        currentPlayingWaveSurfer.pause();
      }
      wavesurfer.current.play();
      currentPlayingWaveSurfer = wavesurfer.current;
      setIsPlaying(true);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="row-reverse"
      alignItems="center"
      gap="10px"
      justifyContent="center"
      width={"20rem"}
      sx={{ maxWidth: 600, margin: "auto" }}
    >
      <Box
        ref={waveformRef}
        sx={{
          width: "100%",
          height: "100px",
          overflow: "hidden",
        //   marginBottom: 2,
        }}
      ></Box>
      <Box display="flex" justifyContent="center" gap={2}>
        <IconButton onClick={handlePlayPause} sx={{ color: "#DA6E0B" }}>
          {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
        </IconButton>
      </Box>
    </Box>
  );
};

export default AudioWave;
