import create from 'zustand';
import { profileModel, teleConsultationModel } from '../Axios/AxiosConfig';
import axios from 'axios';
import { getUserDetails } from '../Auth/AuthUtil';
// import Prescription from '../Tele_Consultation/Prescription';

const TeleConsultationStore = create((set) => ({
    Doctors: [],

    DoctorDetails: null,

    PrescriptionData: [],

    PrescriptionOneData: null,

    AppointmentDetails: null,

    Slots: [],

    slotErrResponse: null,

    isDoctorsLoading: true,

    isPrescriptionLoading: true,

    isPrescriptionOneLoading: true,

    isSlotsfetched: true,


    getDoctors: async () => {
        try {
            let res = await teleConsultationModel.get('/getAllDoctors');

            if (res?.status === 200 && res?.data) {
                set({
                    Doctors: res?.data,
                    isDoctorsLoading: false,
                });
            } else {
                set({
                    isDoctorsLoading: false,
                });
                console.log('Failed to fetch doctors. Status code:', res.status);
            }
        } catch (err) {
            console.log(err?.response?.data);
            set({
                isDoctorsLoading: false,
            });
        }
    },


    getDoctorDetails: async (id) => {
        try {
            set({
                isDoctorsLoading: true,
            });
            let res = await teleConsultationModel.get(`/getDoctor/${id}`);

            if (res?.status === 200 && res?.data) {
                set({
                    DoctorDetails: res?.data,
                    isDoctorsLoading: false,
                });
            } else {

                set({
                    isDoctorsLoading: false,
                });

                console.log('Failed to fetch doctors. Status code:', res.status);
            }
        }
        catch (err) {
            console.log(err?.response?.data);
            set({
                isDoctorsLoading: false,
            });
        }
    },


    getSlots: async (Doc_id, selectedDay) => {
        try {

            set({ slotErrResponse: null })

            let res = await teleConsultationModel.post(`/BookSlots`, {
                Doc_id,
                selectedDay
            });

            if (res?.status === 200 && res?.data) {
                set({
                    Slots: res?.data,
                    isSlotsfetched: false,
                    slotErrResponse: null
                });
            }
        }
        catch (err) {
            console.log(err?.response?.data);
            set({
                Slots: [],
                slotErrResponse: err?.response?.data
            });
        }
    },


    setAppointmentDetails: async (data) => {
        set({ AppointmentDetails: data })
    },


    getPrescription: async () => {
        try {
            const userDetails = getUserDetails();

            const res = await profileModel.post(`/getPrescription`, {
                id: userDetails?.UID
            })

            if (res.status == 200) {
                set({
                    PrescriptionData: res.data,
                    isPrescriptionLoading: false,
                })
            }
        }

        catch (err) {
            console.log(err)
        }
    },


    getOnePrescription: async (id) => {
        try {
            const userDetails = getUserDetails();

            const res = await profileModel.post(`/getOnePrescription`, {
                UID: userDetails?.UID,
                id
            })

            if (res.status == 200) {
                set({
                    PrescriptionOneData: res.data,
                    isPrescriptionOneLoading: false,
                })
            }
        }

        catch (err) {
            console.log(err)
        }
    }



}));

export default TeleConsultationStore;
