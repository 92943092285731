import create from 'zustand';

const persistMiddleware = (config) => (set, get, api) => config(
    (args) => {
        set(args);
        sessionStorage.setItem('Prescription', JSON.stringify(get()));
    },
    get,
    api
);


const usePrescriptionStore = create(persistMiddleware((set) => ({
    selectedPatientdetails: JSON.parse(sessionStorage.getItem('Prescription'))?.selectedPatientdetails || null,
    setSelectedPatientdetails: (data) => set({ selectedPatientdetails: data }),
    setSelectedAppointemntdetails: (data) => set({ selectedAppointemntdetails: data }),
    selectedAppointemntdetails: JSON.parse(sessionStorage.getItem('Prescription'))?.selectedAppointemntdetails || null,

    setAudioBase64: (data) => set({ audioBase64: data }),
    audioBase64: JSON.parse(sessionStorage.getItem('Prescription'))?.audioBase64 || null,

    setPrescription: (data) => set({ selectedPrescription: data }),
    selectedPrescription: JSON.parse(sessionStorage.getItem('Prescription'))?.selectedPrescription || null,
})));

export default usePrescriptionStore;