import React, { useContext } from 'react';
import Sidebar, { SidebarItem } from './Sidebar';
import '../App.css';
import Cookies from 'js-cookie';
import { IconButton } from '@mui/material';
import { Outlet, Link } from 'react-router-dom';
import { SidebarContext } from './Sidebar'; // Import the context

function Home() {
    const doctorDetailsCookie = Cookies.get('Doctor_details');
    let Doctor_details = {};

    if (doctorDetailsCookie) {
        try {
            Doctor_details = JSON.parse(doctorDetailsCookie);
        } catch (error) {
            console.log('Error parsing Doctor_details cookie:', error);
        }
    }

   

    return (
        <div className="home-container flex">
            <Sidebar Doctor_details={Doctor_details}>
                <Link to='/'>
                    <SidebarItem icon={<img src='https://aiwellassets.s3.ap-south-1.amazonaws.com/home.png' alt="Home" style={{ width: "2rem" }} />} text={"Home"} active />
                </Link>
                <Link to="Appointments">
                    <SidebarItem icon={<img src='https://aiwellassets.s3.ap-south-1.amazonaws.com/My+Appointment.png' alt="Appointments" style={{ width: "2rem" }} />} text={"Appointments"} />
                </Link>
                <SidebarItem icon={<img src='https://aiwellassets.s3.ap-south-1.amazonaws.com/patient+icon.png' alt="Patient list" style={{ width: "2rem" }} />} text={"Patient list"} />
            </Sidebar>
            <div className="home-content flex-1">
                <nav className='home-content-nav flex flex-row p-2 mb-50'>
                    <div className="flex-1">
                        <p>Hello, {Doctor_details?.name}</p>
                    </div>
                    <div>
                        <IconButton>
                            <img src='/icons/notification.png' alt="Notifications" style={{ width: "2rem" }} />
                        </IconButton>
                    </div>
                </nav>
                
                <div className=''>
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

export default Home;
