import React, { useState, useRef } from 'react';
import { Button, IconButton } from '@mui/material';
import Lottie from 'lottie-react';
import recordAnimation from '../animations/Animation - 1721626710750.json';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios'; // For translation API request
import usePrescriptionStore from '../DataRepository/Prescription';

// Available language options
const languages = [
  { code: 'en-IN', name: 'English (India)' },
  { code: 'hi-IN', name: 'Hindi' },
  { code: 'ta-IN', name: 'Tamil' },
  { code: 'te-IN', name: 'Telugu' },
  { code: 'ml-IN', name: 'Malayalam' },
];

function AudioRecorder({ onTranscriptChange, onOriginalTranscriptChange }) {
  const [recording, setRecording] = useState(false);
  const { audioBase64, setAudioBase64 } = usePrescriptionStore();
  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('en-IN'); // Language selector

  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const audioRef = useRef(null);
  const recognitionRef = useRef(null); // Store recognition instance in useRef

  const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

  const handleStartRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        mediaRecorderRef.current = new MediaRecorder(stream);

        recognitionRef.current = new SpeechRecognition();
        recognitionRef.current.continuous = true;
        recognitionRef.current.interimResults = true;
        recognitionRef.current.lang = selectedLanguage;

        mediaRecorderRef.current.ondataavailable = event => {
          audioChunksRef.current.push(event.data);
        };

        mediaRecorderRef.current.onstop = () => {
          const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
          const reader = new FileReader();
          reader.readAsDataURL(audioBlob);
          reader.onloadend = () => {
            const base64String = reader.result;
            setAudioBase64(base64String);
          };
          audioChunksRef.current = [];
        };

        mediaRecorderRef.current.start();
        setRecording(true);


        recognitionRef.current.start();
        recognitionRef.current.onresult = (event) => {
          const speechToText = Array.from(event.results)
            .map(result => result[0].transcript)
            .join('');


          if (selectedLanguage !== 'en-IN') {
            onOriginalTranscriptChange(speechToText);
            translateToEnglish(speechToText);
          }
          else {
            onTranscriptChange(speechToText);
          }

        };

        recognitionRef.current.onerror = (error) => {
          console.error('Speech recognition error:', error);
        };

        recognitionRef.current.onend = () => {
          console.log('Speech recognition ended.');
        };
      })
      .catch(error => {
        console.error('Error accessing microphone:', error);
        alert('Could not access microphone. Please check your permissions.');
      });
  };

  const handleStopRecording = () => {
    if (mediaRecorderRef.current && recording) {
      mediaRecorderRef.current.stop();
      setRecording(false);

      if (recognitionRef.current) {
        recognitionRef.current.stop();
      }
    }
  };

  // Function to translate speech text to English using Google Translate API
  const translateToEnglish = async (text) => {
    try {
      const response = await axios.post(
        `https://translation.googleapis.com/language/translate/v2`,
        {},
        {
          params: {
            q: text,
            target: 'en', // Translate to English
            key: 'AIzaSyAIshCSsRQsR88kEd15jzBUlelgQS9ZnmU',
          },
        }
      );
      const translatedText = response.data.data.translations[0].translatedText;

      onTranscriptChange(translatedText);

    } catch (error) {
      console.error('Error translating text:', error);
    }
  };

  const handlePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleDeleteRecording = () => {
    setAudioBase64('');
    onOriginalTranscriptChange('')
    onTranscriptChange('');
    setIsPlaying(false);
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  };

  return (
    <div>
      <select
        value={selectedLanguage}
        onChange={(e) => setSelectedLanguage(e.target.value)}
        style={{ marginBottom: '10px', padding: '5px' }}
      >
        {languages.map(lang => (
          <option key={lang.code} value={lang.code}>
            {lang.name}
          </option>
        ))}
      </select>

      {recording ? (
        <Button
          onClick={handleStopRecording}
          style={{
            width: '100%',
            textTransform: 'none',
            backgroundColor: '#FDFDFD',
            border: "1px solid #FF6666",
            borderRadius: '5px',
            fontFamily: "'Poppins', sans-serif",
            fontWeight: 500,
            padding: '10px',
          }}
        >
          <Lottie animationData={recordAnimation} style={{ width: '3rem', height: '2rem' }} />
          <StopIcon style={{ width: '3rem', color: "#FF6666" }} />
        </Button>
      ) :

        (audioBase64 ? null : (
          <Button
            onClick={handleStartRecording}
            style={{
              width: '100%',
              textTransform: 'none',
              backgroundColor: '#8CEB8C',
              color: '#FDFDFD',
              borderRadius: '5px',
              fontFamily: "'Poppins', sans-serif",
              fontWeight: 500,
              padding: '10px',
            }}
          >
            <MicIcon style={{ width: '3rem' }} />
            &nbsp;Record
          </Button>
        )
        )}

      {audioBase64 && (
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
          <Button
            onClick={handlePlayPause}
            style={{
              textTransform: 'none',
              backgroundColor: '#8CEB8C',
              color: '#FDFDFD',
              borderRadius: '5px',
              fontFamily: "'Poppins', sans-serif",
              fontWeight: 500,
              padding: '10px',
              marginRight: '10px',
            }}
          >
            {isPlaying ? <PauseIcon style={{ width: '3rem' }} /> : <PlayArrowIcon style={{ width: '3rem' }} />}
            &nbsp;{isPlaying ? 'Pause' : 'Play'}
          </Button>
          <IconButton onClick={handleDeleteRecording} style={{ color: '#FF6666' }}>
            <DeleteIcon />
          </IconButton>
          <audio ref={audioRef} src={audioBase64} onEnded={() => setIsPlaying(false)} />
        </div>
      )}



    </div>
  );
}

export default AudioRecorder;
