import React from 'react'
import { Grid, IconButton } from '@mui/material';
import { useRef, useState, useEffect } from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import Cookies from 'js-cookie';
import { Box, Typography } from '@mui/material';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import axios from 'axios';
function Main() {
    const containerRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const [DateType, setType] = useState("monthly")
    const [Appointmentdata, setData] = useState(null)


    useEffect(() => {
        const updateDimensions = () => {
            if (containerRef.current) {
                setDimensions({
                    width: containerRef.current.offsetWidth,
                    height: containerRef.current.offsetHeight,
                });
            }
        };


        updateDimensions();


        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    const doctorDetailsCookie = Cookies.get('Doctor_details');
    let Doctor_details = {};

    if (doctorDetailsCookie) {
        try {
            Doctor_details = JSON.parse(doctorDetailsCookie);
        } catch (error) {
            console.log('Error parsing Doctor_details cookie:', error);
        }
    }

    const getAppointmentData = async () => {
        try {
            let res = await axios.post('https://z2t1vcmobj.execute-api.ap-south-1.amazonaws.com/dev/Appointmentdata', {
                DateType,
                Doc_id: Doctor_details.UID
            })
            if (res.status == 200) {
                setData(res.data)
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getAppointmentData()
    }, [DateType])

    const chartData = {
        data: [
            { id: 0, value: Appointmentdata?.categoryCounts?.Respiratory, label: 'Respiratory' },
            { id: 1, value: Appointmentdata?.categoryCounts?.Mental, label: 'Mental' },
            { id: 2, value: Appointmentdata?.categoryCounts?.Gastro, label: 'Gastro' },
            { id: 3, value: Appointmentdata?.categoryCounts?.['Hair & Skin'], label: 'Hair & Skin' },
            { id: 4, value: Appointmentdata?.categoryCounts?.Physical, label: 'Physical' },
            { id: 5, value: Appointmentdata?.categoryCounts?.Women, label: 'Women' },
            { id: 6, value: Appointmentdata?.categoryCounts?.Sexual, label: 'Sexual' },
            { id: 7, value: Appointmentdata?.categoryCounts?.Metabolic, label: 'Metabolic' },
        ]
    };

    const COLORS = ["#75623F", '#918165', '#A3967E', '#C0b7a7', '#F4D2B3', '#EEBC8F', '#E69E56', '#DA6E0B'];

    return <div>
        <div className='grid-container'>
            <div className='gcontent'>
                <p>Total Appointments</p>
                <p>{Appointmentdata?.totalAppointments || 0}</p>
            </div>
            <div className='gcontent'>
                <p>Completed</p>
                <p>{Appointmentdata?.statusCounts?.completed || 0}</p>
            </div>
            <div className='gcontent'>
                <p>Cancelled</p>
                <p>{Appointmentdata?.statusCounts?.cancelled || 0}</p>
            </div>
            <div className='gcontent'>
                <p>Rescheduled</p>
                <p>{Appointmentdata?.statusCounts?.rescheduled || 0}</p>
            </div>

        </div>
        <div className='Appointment-insights-container'>
            <div className='Apppointment-insights-page'>
                <div>
                    <p>Appointment Insights</p>
                    <div className="select-container">
                        <select value={DateType} className='styled-select' onChange={(e) => {
                            setType(e.target.value)
                        }}>
                            <option value={"daily"}>Daily</option>
                            <option value={"weekly"}>Weekly</option>
                            <option value={"monthly"}>Monthly</option>
                            <option value={"yearly"}>Yearly</option>

                        </select>
                    </div>
                </div>

                <div className='charts'>
                    <div ref={containerRef}>
                        {Appointmentdata?.weekCounts && (
                            <BarChart
                                xAxis={[{ scaleType: 'band', data: ['week1', 'week2', 'week3'] }]}
                                yAxis={[
                                    {
                                        label: 'No of Appointments',
                                    },
                                ]}
                                colors={["#8CEB8C", "#75623F", "#DA6E0B"]}
                                width={400}
                                height={300}
                                series={[
                                    {
                                        label: 'Upcoming',
                                        data: Object.values(Appointmentdata?.weekCounts.week1)
                                    },
                                    {
                                        label: 'Cancelled',
                                        data: Object.values(Appointmentdata?.weekCounts.week2)
                                    },
                                    {
                                        label: 'Rescheduled',
                                        data: Object.values(Appointmentdata?.weekCounts.week3)
                                    },
                                ]}
                                borderRadius={20}
                                sx={{
                                    [`.${axisClasses.left} .${axisClasses.label}`]: {
                                        transform: 'translate(-10px)',
                                        // fontSize: '12px', // Reduce font size for y-axis label
                                    },
                                    [`.${axisClasses.dataLabelClass}`]: {
                                        // fontSize: '10px', // Reduce font size for data labels
                                    },
                                }}
                                slotProps={{
                                    legend: {
                                        direction: 'row',
                                        position: { vertical: 'bottom', horizontal: 'center' },
                                        padding: 0,
                                        itemMarkWidth: 10,
                                        itemMarkHeight: 10,
                                        labelStyle: {
                                            fontSize: 12,
                                        },
                                    },
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className='Apppointment-insights-page'>
                <div>
                    <p>Wellness Categoires</p>
                </div>

                <div className='charts'>
                    <div ref={containerRef}>
                        {Appointmentdata?.categoryCounts && (
                            <Box >

                                <PieChart display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={2}
                                    series={[
                                        {
                                            data: chartData.data.map((item, index) => ({
                                                id: item.id,
                                                value: item.value,
                                                label: item.label,
                                                color: COLORS[index % COLORS.length]
                                            })),
                                            innerRadius: 40,
                                            outerRadius: 100,
                                            highlightScope: { highlighted: 'item', faded: 'global' },
                                        },
                                    ]}
                                    width={300}
                                    height={300}
                                    slotProps={{
                                        legend: {
                                            direction: 'row',
                                            position: { vertical: 'bottom', horizontal: 'center' },
                                            padding: 0,
                                            itemMarkWidth: 10,
                                            itemMarkHeight: 10,
                                            labelStyle: {
                                                fontSize: 12,
                                            },
                                        },
                                    }}
                                />
                            </Box>
                        )}
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Main