import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { isTokenExpired, refreshToken, getUserDetails } from './AuthUtil';

import axios from 'axios';
import { CircularProgress } from '@mui/material';


const cookies = new Cookies();

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(null);


  useEffect(() => {
    const checkAuthentication = async () => {
      let accessToken = cookies.get('accessToken');

      if (!accessToken || isTokenExpired(accessToken)) {
        const refreshed = await refreshToken();
        accessToken = cookies.get('accessToken');
        const id = getUserDetails()?.UID
        let doctor_response = await axios.get(`https://z2t1vcmobj.execute-api.ap-south-1.amazonaws.com/dev/getDoctor_details/${id}`)
        if (doctor_response.status === 200) {
          cookies.set('Doctor_details', JSON.stringify(doctor_response.data));
        }

        if (!refreshed || !accessToken) {
          setIsAuthenticated(false);
          return;
        }
      }

      if (isTokenExpired(accessToken)) {
        setIsAuthenticated(false);
      } else {
        setIsAuthenticated(true);
      }
    };

    checkAuthentication();
  }, []);

  useEffect(() => {
    if (isAuthenticated === false) {
      navigate('/login', { state: { from: location } });
    }
  }, [isAuthenticated]);


  if (isAuthenticated === null) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100vw", height: '100vh' }}>
        <div style={{ position: "relative", width: "10rem", height: "10rem" }}>
          <CircularProgress style={{ width: "100%", height: "100%", color: "#DA6E0B" }} thickness={1} />
          <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img src='./icons/loading image 1.png' style={{ width: "80%", height: "80%" }} alt="Loading" />
          </div>
        </div>
      </div>
    );
  }

  return isAuthenticated ?

    children

    : null;
};

export default ProtectedRoute;
