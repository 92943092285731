import React from 'react'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { Button, IconButton } from '@mui/material'
import { useParams, Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CheckCircle, ArrowUpward, Edit } from '@mui/icons-material';
import { EditIcon } from 'lucide-react'
import UpgradeIcon from '@mui/icons-material/Upgrade';
import { profileModel } from '../Axios/AxiosConfig'
import { Dialog, DialogTitle, Typography, TextField, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import usePrescriptionStore from '../DataRepository/Prescription'

import DoshaBarChart from './VikritiChart'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import axios from "axios"
import { teleConsultationModel } from '../Axios/AxiosConfig'



function Patient_Profile() {
    let { id } = useParams()

    let navigate = useNavigate()

    const [open, setOpen] = useState(false)
    const [vScore, setVScore] = useState(0);
    const [pScore, setPScore] = useState(0);
    const [kScore, setKScore] = useState(0);
    const [VPK, setVPK] = useState('');

    const handleClose = () => {
        setOpen(false)
    }

    const handleOpen = () => {
        setOpen(true)
    }


    const [open3, setOpen3] = useState(false)

    const handleClose3 = () => {
        setOpen3(false)
    }

    const handleOpen3 = () => {
        setOpen3(true)
    }


    const [open4, setOpen4] = useState(false)

    const [open5, setOpen5] = useState(false)

    const handleClose4 = () => {
        setOpen4(false)
    }

    const handleOpen4 = () => {
        setOpen4(true)
    }

    const handleClose5 = () => {
        setOpen5(false)
    }

    const handleOpen5 = () => {
        setOpen5(true)
    }

    let { setSelectedPatientdetails, setSelectedAppointemntdetails } = usePrescriptionStore()

    let [user, setuser] = useState()


    function findVPKResult(arrayList) {
        try {
            const datamap = {
                "V": arrayList[0],
                "P": arrayList[1],
                "K": arrayList[2]
            };

            const datamap1 = {
                [arrayList[0]]: "V",
                [arrayList[1]]: "P",
                [arrayList[2]]: "K"
            };


            const v = Math.max(...arrayList);
            const k = Math.min(...arrayList);

            const p = arrayList.reduce((sum, current) => sum + current, 0) - (v + k);

            if (v - p > 20) {
                return datamap1[v];
            } else if (v - p <= 20 && p - k > 5) {
                if (v === p) {
                    const dominant = Object.entries(datamap)
                        .filter(([key, value]) => value === v)
                        .map(([key]) => key)
                        .join('');
                    return dominant;
                } else {
                    return datamap1[v] + datamap1[p];
                }
            } else {
                return datamap1[v] + datamap1[p] + datamap1[k];
            }
        } catch (error) {
            console.error("Error in findVPKResult:", error);
            return null;
        }
    }


    const getPatientProfile = async () => {
        try {
            let res = await teleConsultationModel.post('getPatientProfile', {
                id
            })
            if (res.status == 200) {
                setuser(res.data)
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getPatientProfile()
    }, [])



    function calculateDoshaValues(vikritiResponse) {
        let v = 0;
        let p = 0;
        let k = 0;

        const uniqueCategories = new Set();

        vikritiResponse?.vikritiList?.forEach((vikriti) => {
            vikriti.dosha_increase.split(",").forEach((dosha) => {
                switch (dosha.trim().toLowerCase()) {
                    case "v":
                        v++;
                        break;
                    case "p":
                        p++;
                        break;
                    case "k":
                        k++;
                        break;
                }
            });

            vikriti.dosha_decrease.split(",").forEach((dosha) => {
                switch (dosha.trim().toLowerCase()) {
                    case "v":
                        v--;
                        break;
                    case "p":
                        p--;
                        break;
                    case "k":
                        k--;
                        break;
                }
            });

            uniqueCategories.add(vikriti.category);
        });

        const totalDoshas = vikritiResponse?.vikritiList?.length;

        const vPercent = (v / totalDoshas) * 100;

        const pPercent = (p / totalDoshas) * 100;

        const kPercent = (k / totalDoshas) * 100;

        return {
            doshaValues: { v: vPercent, p: pPercent, k: kPercent },
            uniqueCategories: Array.from(uniqueCategories)
        };
    }

    function calculatePercentages(vikritiResponse) {
        const { doshaValues, uniqueCategories } = calculateDoshaValues(vikritiResponse);

        const vataFloat = doshaValues.v;
        const pittaFloat = doshaValues.p;
        const kaphaFloat = doshaValues.k;

        const vataPulse = vikritiResponse?.vataPulseScore || 0;
        const pittaPulse = vikritiResponse?.pittaPulseScore || 0;
        const kaphaPulse = vikritiResponse?.kaphaPulseScore || 0;

        const totalPulse = Math.abs(vataPulse) + Math.abs(pittaPulse) + Math.abs(kaphaPulse);
        let vataFloatPulse = 0, pittaFloatPulse = 0, kaphaFloatPulse = 0;

        if (totalPulse !== 0) {
            vataFloatPulse = (vataPulse * 100) / totalPulse;
            pittaFloatPulse = (pittaPulse * 100) / totalPulse;
            kaphaFloatPulse = (kaphaPulse * 100) / totalPulse;
        }

        const vataScore = vataFloat || 0;
        const vataPulseScore = vataFloatPulse || 0;
        const pittaScore = pittaFloat || 0;
        const pittaPulseScore = pittaFloatPulse || 0;
        const kaphaScore = kaphaFloat || 0;
        const kaphaPulseScore = kaphaFloatPulse || 0;

        const vPercent = (0.7 * vataScore + 0.3 * vataPulseScore);
        const pPercent = (0.7 * pittaScore + 0.3 * pittaPulseScore);
        const kPercent = (0.7 * kaphaScore + 0.3 * kaphaPulseScore);

        const doshaPercentages = { v: Math.floor(vPercent), p: Math.floor(pPercent), k: Math.floor(kPercent) };


        return { doshaPercentages, uniqueCategories };
    }


    const vikritiresultData = calculatePercentages(user?.Vikriti_details)

    const findDominantDosha = (prakritiState) => {
        if (prakritiState) {
            if (prakritiState?.vata >= Math.max(prakritiState?.pitta, prakritiState?.kapha)) {
                return ['Vata', "Dominant", `${prakritiState?.vata?.toFixed(0)}%`];
            } else if (prakritiState.pitta >= Math.max(prakritiState?.vata, prakritiState?.kapha)) {
                return ['Pitta', "Dominant", `${prakritiState?.pitta?.toFixed(0)}%`];
            } else {
                return ['Kapha', "Dominant", `${prakritiState?.kapha?.toFixed(0)}%`];
            }
        }
        return ['', "Dominant", , 0];
    };

    const [editQuestionIndex, setEditQuestionIndex] = useState(null);
    const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);


    const handleEditAnswer = (questionIndex) => {
        setEditQuestionIndex(questionIndex);
    };

    const handleAnswerChange = (questionIndex, newAnswerIndex) => {
        const updatedPrakritiDetails = user.Prakriti_details.PrakritiCollection?.map((q, qIndex) => {
            if (qIndex === questionIndex) {
                return {
                    ...q,
                    Answers: q.Answers.map((ans, ansIndex) => ({
                        ...ans,
                        isSelected: ansIndex === newAnswerIndex,
                    })),
                };
            }
            return q;
        });

        setuser({
            ...user,
            Prakriti_details: {
                ...user.Prakriti_details,
                PrakritiCollection: updatedPrakritiDetails,
            },
        });

        setSelectedAnswerIndex(newAnswerIndex);
        setEditQuestionIndex(null);
    };

    const handleKeyPress = (event) => {
        const charCode = event.charCode;
        if (event.keyCode !== 8 && (charCode < 48 || charCode > 57)) {
            event.preventDefault();
        }
    };

    const handleSaveChanges = async () => {
        try {
            for (const question of user.Prakriti_details.PrakritiCollection) {
                const selectedAnswer = question.Answers.find((answer) => answer.isSelected);
                if (selectedAnswer) {
                    console.log('Score Updating')
                    await updateScores(selectedAnswer);
                }
            }

            handleSubmitResult();
        } catch (error) {
            console.error("Error in handleSaveChanges:", error);
        }
    };



    const updateScores = (newAnswer) => {
        if (newAnswer && newAnswer.score && newAnswer.vpk) {
            const newScore = parseFloat(newAnswer.score) || 0;

            switch (newAnswer.vpk.toLowerCase()) {
                case 'v':
                    setVScore(prevScore => prevScore + newScore);
                    break;
                case 'p':
                    setPScore(prevScore => prevScore + newScore);
                    break;
                case 'k':
                    setKScore(prevScore => prevScore + newScore);
                    break;
            }
        }
    };


    // function calculatePercentages(vScore, pScore, kScore) {
    //     const totalScore = vScore + pScore + kScore;
    //     if (totalScore > 0) {
    //         const vScorePercentage = (vScore / totalScore) * 100;
    //         const pScorePercentage = (pScore / totalScore) * 100;
    //         const kScorePercentage = (kScore / totalScore) * 100;

    //         return {
    //             vScorePercentage: parseFloat(vScorePercentage?.toFixed(2)),
    //             pScorePercentage: parseFloat(pScorePercentage?.toFixed(2)),
    //             kScorePercentage: parseFloat(kScorePercentage?.toFixed(2)),
    //         };
    //     } else {
    //         return {
    //             vScorePercentage: 0.0,
    //             pScorePercentage: 0.0,
    //             kScorePercentage: 0.0,
    //         };
    //     }
    // }

    async function findVPK(arrayList) {
        try {
            const datamap = { V: arrayList[0], P: arrayList[1], K: arrayList[2] };
            const datamap1 = {
                [arrayList[0]]: "V",
                [arrayList[1]]: "P",
                [arrayList[2]]: "K",
            };

            const sortedList = [...arrayList].sort((a, b) => a - b);
            const [k, p, v] = sortedList;

            if (v - p > 20) return datamap1[v];
            if (v - p <= 20 && p - k > 5) {
                return v === p ? Object.entries(datamap).filter(([_, val]) => val === v).map(([key]) => key).join('') : `${datamap1[v]}${datamap1[p]}`;
            }
            return `${datamap1[v]}${datamap1[p]}${datamap1[k]}`;
        } catch (error) {
            console.error("Error in findVPK:", error);
            throw error;
        }
    }

    const handleSubmitResult = async () => {
        try {
            const percentages = calculatePercentages(vScore, pScore, kScore);

            const VPk_result = await findVPK([
                percentages?.vScorePercentage,
                percentages?.pScorePercentage,
                percentages?.kScorePercentage,
            ]);

            setVPK(VPk_result);

            console.log("VPK Result:", VPk_result);
            console.log("Raw Scores:", { vScore, pScore, kScore });

            await updatePrakritiResult(
                percentages?.vScorePercentage,
                percentages?.pScorePercentage,
                percentages?.kScorePercentage,
                VPk_result
            );
        } catch (error) {
            console.error("Error in handleSubmitResult:", error);
        }
    };


    const updatePrakritiResult = async (vata, pitta, kapha, VPk_result) => {
        try {
            const res = await profileModel.post('/UpdatePrakriti', {
                UID: user?.Appointment_details?.patient_id,
                result: VPk_result,
                date: Date.now().toString(),
                vata: Math.round(vata),
                pitta: Math.round(pitta),
                kapha: Math.round(kapha),
                rawVata: vScore,
                rawKapha: kScore,
                rawPitta: pScore,
                id: user?.Appointment_details?.patient_id,
                PrakritiCollection: user.Prakriti_details.PrakritiCollection,
            });

            if (res.status === 200) {
                handleClose()
                getPatientProfile()
            }
        } catch (err) {
            console.error("Error updating Prakriti result:", err.response?.data || err);
        }
    };

    const EditPrakritiResult = async () => {
        try {
            const res = await profileModel.post('/UpdatePrakriti', {
                UID: user?.Appointment_details?.patient_id,
                ...user?.Prakriti_details
            });

            if (res.status === 200) {
                handleClose4()
                getPatientProfile()
            }
        } catch (err) {
            console.error("Error updating Prakriti result:", err.response?.data || err);
        }
    };

    const UpdateVkikriti = async () => {
        try {
            let res = await profileModel.post('/UpdateVikriti', {
                ...user?.Vikriti_details
            })

            if (res?.status === 200 && res?.data) {
                handleClose3()
                getPatientProfile()
            }
        }

        catch (err) {
            console.log(err)
        }
    }


    console.log(user?.Vikriti_details)

    const groupedData = user?.Vikriti_details?.vikritiList?.reduce((acc, item) => {
        if (!acc[item.category]) {
            acc[item.category] = [];
        }
        acc[item.category].push(item);
        return acc;
    }, {});


    return <div className='Patient_Profile'>
        <nav className='flex flex-row p-2'>
            <IconButton component={Link} to={`/Appointments`}>
                <ChevronLeft></ChevronLeft>
            </IconButton>

            <p>Patient Profile</p>

        </nav>
        <div className='Patient_Profile_list_container'>
            <div className='Patient-info'>

                <div className='Patient-card'>

                    {user?.Patient_details?.src && user?.Patient_details?.src != '' ?
                        <div className='Patient-avatar'>
                            <img src={user?.Patient_details?.src}></img>
                        </div> :
                        <div className='Patient-avatar'>
                            <p><p>{user?.Patient_details?.firstname.charAt(0)} {user?.Patient_details?.lastname.charAt(0)}</p></p>
                        </div>}

                    <div className='Patient-info-details'>
                        <p className='patient_name'>{user?.Patient_details?.firstname} {user?.Patient_details?.lastname}</p>
                        <div>
                            <img src={user?.Patient_details?.gender === "Male" ? '/icons/Male_icon.png' : "/icons/female_icon.png"}></img>
                            <p>{user?.Patient_details?.gender}</p>
                        </div>
                        <div>
                            <img src='/icons/age.png'></img>
                            <p>{user?.Patient_details?.dob} years</p>
                        </div>

                        <div>
                            <img src='/icons/weight.png'></img>
                            <p>{user?.Patient_details?.weight}</p>
                        </div>

                        <div>
                            <img src='/icons/height.png'></img>
                            <p>{user?.Patient_details?.height}</p>
                        </div>

                    </div>

                </div>

                <div className='Quickatbtn'>
                    <div>
                        <img src="/icons/My prescription.png"></img>

                        <p>Prescription</p>
                    </div>
                    <IconButton onClick={() => {
                        setSelectedPatientdetails(user?.Patient_details)
                        setSelectedAppointemntdetails(user?.Appointment_details)
                        navigate(`/Precription/${user?.Patient_details?.UID}`)
                    }}>
                        <ChevronRight></ChevronRight>
                    </IconButton>
                </div>

                <div className='Quickatbtn'>
                    <div>
                        <img src="/icons/My reports.png"></img>
                        <p>Report</p>
                    </div>
                    <IconButton component={Link} to={`/Reports/${user?.Appointment_details?.patient_id}`}>
                        <ChevronRight></ChevronRight>
                    </IconButton>
                </div>
            </div>

            <div className='Patient-more-info'>
                <div className='Patient-appointment-details'>
                    <div className='Patient-appointment-details-title'>
                        <p>Appointment details</p>
                    </div>
                    <div className='flex flex-row w-full p-2 justify-evenly appointment-id-section'>
                        <div className='patient-appointment-details-id flex flex-row'>
                            <p>Appointment ID:</p>
                            <p>{id.split("_")[1]}</p>
                        </div>
                        <div className='patient-appointment-details-id flex flex-row '>
                            <p>Patient ID:</p>
                            <p>{user?.Appointment_details?.patient_id}</p>
                        </div>
                    </div>
                    <div className='flex flex-row justify-evenly p-2 Patient-info-details'>
                        <div>
                            <img src='/icons/date.png' alt="Date" />
                            <p>{user?.Appointment_details?.appointment_date.split('-').reverse().join('-')}</p>
                        </div>
                        <div>
                            <img src='/icons/clock.png' alt="Time" />
                            <p>{user?.Appointment_details?.appointment_time}</p>
                        </div>
                        <div>
                            <img src={user?.Appointment_details?.consultation_type == "Video" ?
                                "/icons/video.png" : "/icons/Audio.png"} alt="Consultation Type" />
                            <p>{user?.Appointment_details?.consultation_type}</p>
                        </div>
                    </div>
                </div>

                <div className='mt-5 Patient-wellnessCategories'>
                    {user?.Wellness_Category?.Categories && <p>Wellness Categories</p>}
                    <div className="flex flex-row mt-2 items-center flex-wrap gap-5">
                        {user?.Wellness_Category?.Categories?.map((e, i) => {
                            if (e.selected) {
                                return (
                                    <div key={i} className="text-center">
                                        <img src={e.imageUrl} alt={e?.name} className="mx-auto mb-2 wellness-img" />
                                        <p>{e?.name}</p>
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </div>
                </div>

                <div className='flex flex-row items-center justify-evenly'>
                    <div className='PrakritiChart'>
                        <div className='Prakriti-result-item'>
                            <p>Prakriti Result</p>
                            <div className="circleIndicatorContainer mt-5">
                                <div style={{ position: 'relative', width: 250, height: 250 }}>
                                    {/* Outer Circle (Vata) */}
                                    <CircularProgressbar
                                        value={user?.Prakriti_details?.vata}
                                        className="first-circle"
                                        strokeWidth={1}
                                        styles={buildStyles({
                                            pathColor: "#75623F",
                                            trailColor: "transparent",
                                            textColor: "#ffffff",
                                        })}
                                    />
                                    {/* Label for Vata */}
                                    <div style={{ position: 'absolute', top: '-5%', transform: 'translateY(-0%)', right: '50%', textAlign: 'right' }}>
                                        <span className='prakritilabel'>Vata</span>
                                    </div>

                                    <div style={{ position: 'absolute', top: 10, left: 10, width: 230, height: 230 }}>

                                        <CircularProgressbar
                                            value={user?.Prakriti_details?.pitta}
                                            className="second-circle"
                                            strokeWidth={1}
                                            styles={buildStyles({
                                                pathColor: "#FF5C00",
                                                trailColor: "transparent",
                                                textColor: "#ffffff",
                                            })}
                                        />
                                    </div>

                                    <div style={{ position: 'absolute', top: '-1%', transform: 'translateY(-0%)', right: '50%', textAlign: 'right' }}>
                                        <span className='prakritilabel' >Pitta</span>
                                    </div>

                                    <div style={{ position: 'absolute', top: 20, left: 20, width: 210, height: 210 }}>
                                        {/* Inner Circle (Kapha) */}
                                        <CircularProgressbar
                                            value={user?.Prakriti_details?.kapha}
                                            className="third-circle"
                                            strokeWidth={1}
                                            styles={buildStyles({
                                                pathColor: "#6CBA6C",
                                                trailColor: "transparent",
                                                textColor: "#ffffff",
                                            })}
                                        />
                                    </div>

                                    <div style={{ position: 'absolute', top: '2%', transform: 'translateY(-0%)', right: '50%', textAlign: 'right', }}>
                                        <span className='prakritilabel'>Kapha</span>
                                    </div>


                                    <div
                                        className="inner-circle-content"
                                        style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}
                                    >
                                        <span className="inner-circle-text">
                                            <div className="dominant-prakriti-doshas">
                                                {findDominantDosha(user?.Prakriti_details).map((e) => {
                                                    return <p key={e}>{e}</p>;
                                                })}
                                            </div>
                                        </span>
                                    </div>
                                </div>

                            </div>
                            <div className='Prakriti-r-c-item'>
                                <p>Prakriti Assesment</p>
                                <div className='mt-2 ass-lables'>
                                    <div className='flex flex-row items-center justify-evenly'>
                                        <p>{user?.Prakriti_details?.result} dominant</p>
                                        <div>
                                            <IconButton onClick={handleOpen4}>
                                                <EditIcon />
                                            </IconButton>

                                            <IconButton onClick={handleOpen}>
                                                <UpgradeIcon />
                                            </IconButton>
                                        </div>

                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: "100%" }} className='mt-2 ass-lables'>

                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ width: '16px', height: '16px', backgroundColor: '#75623F', marginRight: '8px' }}></div>
                                            <p variant="body1">{Math.round(user?.Prakriti_details?.vata)}%</p>
                                        </div>

                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ width: '16px', height: '16px', backgroundColor: '#FF5C00', marginRight: '8px' }}></div>
                                            <p variant="body1">{Math.round(user?.Prakriti_details?.pitta)}%</p>
                                        </div>

                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ width: '16px', height: '16px', backgroundColor: '#6CBA6C', marginRight: '8px' }}></div>
                                            <p variant="body1">{Math.round(user?.Prakriti_details?.kapha)}%</p>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="VikritiChart">
                        <div className='Prakriti-result-item'>
                            <p>Vikriti Result</p>
                            <div>
                                <DoshaBarChart vikritiresultData={vikritiresultData} style={{ border: "1px solid red" }} />
                            </div>

                            <div className='Prakriti-r-c-item'>
                                <p>Vikriti Assesment</p>
                                <div className='mt-2 ass-lables'>
                                    <div className='flex flex-row items-center justify-evenly'>
                                        <p>{user?.Vikriti_details?.Result} dominant</p>
                                        <div>
                                            <IconButton onClick={handleOpen3}>
                                                <EditIcon />
                                            </IconButton>

                                            <IconButton onClick={handleOpen5}>
                                                <UpgradeIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: "100%" }} className='mt-2 ass-lables'>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ width: '16px', height: '16px', backgroundColor: '#BDC5CC', marginRight: '8px' }}></div>
                                            <p variant="body1">{Math.floor(user?.Vikriti_details?.vataAssScore)}%</p>
                                        </div>

                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ width: '16px', height: '16px', backgroundColor: '#CDB9AB', marginRight: '8px' }}></div>
                                            <p variant="body1">{Math.floor(user?.Vikriti_details?.pittaAssScore)}%</p>
                                        </div>

                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ width: '16px', height: '16px', backgroundColor: '#5BC35B', marginRight: '8px' }}></div>
                                            <p variant="body1">{Math.floor(user?.Vikriti_details?.kaphaAssScore)}%</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="patient-action-item-buttons">
                    <div>
                        <Button
                            style={{
                                width: '100%',
                                textTransform: 'none',
                                borderRadius: '0',
                                border: "1px solid #DA6E0B ",
                                color: '#DA6E0B',
                                boxSizing: 'border-box',
                                borderRadius: "5px",
                                fontFamily: "'Poppins', sans-serif",
                                fontWeight: 500,
                                padding: "10px"
                            }}
                            onClick={""}
                        >
                            Reschedule
                        </Button>
                    </div>
                    <div>
                        <Button
                            style={{
                                width: '100%',
                                textTransform: 'none',
                                borderRadius: '0',
                                border: "1px solid #DA6E0B ",
                                color: '#DA6E0B',
                                boxSizing: 'border-box',
                                borderRadius: "5px",
                                fontFamily: "'Poppins', sans-serif",
                                fontWeight: 500,
                                padding: "10px"
                            }}
                            onClick={() => {
                                setSelectedPatientdetails(user?.Patient_details)
                                setSelectedAppointemntdetails(user?.Appointment_details)
                                navigate(`/Chat/${user?.Appointment_details?.patient_id}/${user?.Appointment_details?.doctor_id}`)
                            }}
                        >
                            Chat
                        </Button>
                    </div>
                    <div>
                        <Button
                            style={{
                                width: '100%',
                                textTransform: 'none',
                                borderRadius: '0',
                                backgroundColor: '#DA6E0B',
                                color: 'white',
                                border: 'none',
                                boxSizing: 'border-box',
                                borderRadius: "5px",
                                fontFamily: "'Poppins', sans-serif",
                                fontWeight: 500,
                                padding: "10px"
                            }}
                            component={Link} to={`/Call/${user?.Appointment_details?.patient_id}/${user?.Appointment_details?.consultation_type}`}>
                            Consult
                        </Button>
                    </div>

                </div>
            </div>

        </div>


        <Dialog fullScreen open={open5} onClose={handleClose5} fullWidth>
            <DialogTitle>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                        Vikriti Assessment Result
                    </Typography>
                    <IconButton onClick={handleClose5}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>

            <div className="PrakritiAssesmentEdit">

                <div className="group-container">
                    {Object.entries(
                        user?.Vikriti_details?.vikritiList?.reduce((acc, item) => {
                            if (!acc[item.category]) {
                                acc[item.category] = [];
                            }
                            acc[item.category].push(item);
                            return acc;
                        }, {}) || {} // Fallback to an empty object if reduce returns undefined/null
                    ).map(([category, items]) => (
                        <div key={category} style={{ marginBottom: "20px" }} className="group-item">
                            <h2>{category}</h2>
                            {items?.map((item, index) => (
                                <div
                                    key={index}
                                    style={{
                                        border: "1px solid #ddd",
                                        padding: "10px",
                                        margin: "10px 0",
                                    }}
                                >
                                    <h4>Sub-Category: {item?.sub_category}</h4>
                                    <p>
                                        <strong>Symptoms:</strong> {item?.symptoms}
                                    </p>
                                    <p>
                                        <strong>Dosha Increase:</strong> {item?.dosha_increase}
                                    </p>
                                    <p>
                                        <strong>Dosha Decrease:</strong> {item?.dosha_decrease}
                                    </p>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>

            </div>

        </Dialog>

        <Dialog fullScreen open={open} onClose={handleClose} fullWidth>
            <DialogTitle>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                        Prakriti Assessment Result
                    </Typography>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>

            <div className="PrakritiAssesmentEdit">
                {user?.Prakriti_details?.PrakritiCollection.map((question, questionIndex) => {
                    const selectedAnswerIndex = question.Answers.findIndex((answer) => answer.isSelected);
                    const selectedAnswer = question.Answers[selectedAnswerIndex];
                    return (
                        <div className="PrakritiAssesmentEdit-container" key={questionIndex}>
                            <div>
                                <p>{question.Question}</p>
                            </div>
                            <div>
                                {editQuestionIndex === questionIndex ? (
                                    <FormControl fullWidth>
                                        <Select
                                            value={selectedAnswerIndex}
                                            onChange={(e) =>
                                                handleAnswerChange(questionIndex, parseInt(e.target.value, 10))
                                            }
                                        >
                                            {question.Answers.map((answer, answerIndex) => (
                                                <MenuItem key={answerIndex} value={answerIndex}>
                                                    {answer.Answer}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                ) : (
                                    // Display the selected answer when not in edit mode
                                    selectedAnswer && (
                                        <div className="answer-item selected">
                                            <div className="answer-content">
                                                <img
                                                    src={selectedAnswer.image}
                                                    alt={selectedAnswer.AnswerTag}
                                                    className="answer-image"
                                                />
                                                <p>{selectedAnswer.Answer}</p>
                                            </div>
                                            <div className="answer-actions">
                                                <CheckCircle color="success" style={{ marginRight: '10px' }} />
                                                {selectedAnswer.vpk && (
                                                    <div className="vpk-indicator">
                                                        <ArrowUpward color="primary" fontSize="small" />
                                                        <span>{selectedAnswer.vpk.toUpperCase()}</span>
                                                    </div>
                                                )}
                                                <Edit
                                                    className="edit-answer-button"
                                                    onClick={() => handleEditAnswer(questionIndex)}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    );
                })}
                <div className='flex flex-row items-center justify-center m-3'>
                    <Button
                        onClick={handleSaveChanges}
                        sx={{
                            textTransform: 'none',
                            borderRadius: '0',
                            backgroundColor: '#DA6E0B',
                            color: 'white',
                            border: 'none',
                            boxSizing: 'border-box',
                            borderRadius: "5px",
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: 500,
                            padding: "10px",
                            '&:hover': {
                                backgroundColor: '#DA6E0B',
                            }
                        }}

                    >
                        Save Changes
                    </Button>
                </div>

            </div>

        </Dialog>

        <Dialog open={open4} onClose={handleClose4} fullWidth maxWidth="sm">
            <DialogTitle>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                        Prakriti Assessment Result
                    </Typography>
                    <IconButton onClick={handleClose4}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>

            <div style={{ padding: '16px', textAlign: 'center' }}>
                <div>
                    <TextField
                        id="standard-search"
                        label="Dominant"
                        value={findVPKResult([user?.Prakriti_details?.vata, user?.Prakriti_details?.pitta, user?.Prakriti_details?.kapha])}
                        type="search"
                        variant="standard"
                    />
                </div>

                <div className='pinput-c'>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ width: '16px', height: '16px', backgroundColor: '#FF9800', marginRight: '8px' }}></div>
                            Vata
                        </div>
                        <input
                            type="number"
                            style={{
                                padding: '8px',
                                border: '1px solid #ccc',
                                width: '100px',
                                textAlign: 'center',
                                background: '#F4F4EE'
                            }}
                            onKeyPress={handleKeyPress}
                            value={user?.Prakriti_details?.vata || ''}
                            onChange={(e) => {
                                const newValue = parseInt(e.target.value.trim(), 10) || 0;
                                const currentPitta = user?.Prakriti_details?.pitta || 0;
                                const currentKapha = user?.Prakriti_details?.kapha || 0;

                                if (newValue + currentPitta + currentKapha <= 100) {
                                    setuser((prevState) => ({
                                        ...prevState,
                                        Prakriti_details: {
                                            ...prevState.Prakriti_details,
                                            vata: newValue
                                        }
                                    }));
                                }
                            }}
                        />
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ width: '16px', height: '16px', backgroundColor: '#795548', marginRight: '8px' }}></div>
                            Pitta
                        </div>
                        <input
                            type="number"
                            style={{
                                padding: '8px',
                                border: '1px solid #ccc',
                                width: '100px',
                                textAlign: 'center',
                                background: '#F4F4EE'
                            }}
                            onKeyPress={handleKeyPress}
                            value={user?.Prakriti_details?.pitta || ''}
                            onChange={(e) => {
                                const newValue = parseInt(e.target.value.trim(), 10) || 0;
                                const currentVata = user?.Prakriti_details?.vata || 0;
                                const currentKapha = user?.Prakriti_details?.kapha || 0;


                                if (newValue + currentVata + currentKapha <= 100) {
                                    setuser((prevState) => ({
                                        ...prevState,
                                        Prakriti_details: {
                                            ...prevState.Prakriti_details,
                                            pitta: newValue
                                        }
                                    }));
                                }
                            }}
                        />
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ width: '16px', height: '16px', backgroundColor: '#8DE995', marginRight: '8px' }}></div>
                            Kapha
                        </div>

                        <input
                            type="number"
                            style={{
                                padding: '8px',
                                border: '1px solid #ccc',
                                width: '100px',
                                textAlign: 'center',
                                background: '#F4F4EE'
                            }}
                            onKeyPress={handleKeyPress}
                            value={user?.Prakriti_details?.kapha || ''}
                            onChange={(e) => {
                                const newValue = parseInt(e.target.value.trim());
                                const currentVata = user?.Prakriti_details?.vata;
                                const currentPitta = user?.Prakriti_details?.pitta;

                                if (newValue + currentVata + currentPitta <= 100) {
                                    setuser((prevState) => ({
                                        ...prevState,
                                        Prakriti_details: {
                                            ...prevState.Prakriti_details,
                                            kapha: newValue
                                        }
                                    }));
                                }
                            }}
                        />
                    </div>
                </div>




                <Button

                    sx={{
                        textTransform: 'none',
                        borderRadius: '0',
                        backgroundColor: '#DA6E0B',
                        color: 'white',
                        border: 'none',
                        boxSizing: 'border-box',
                        borderRadius: "5px",
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 500,
                        padding: "10px",
                        '&:hover': {
                            backgroundColor: '#DA6E0B',
                        }
                    }}

                    onClick={EditPrakritiResult}
                >
                    Save
                </Button>
            </div>
        </Dialog>

        <Dialog open={open3} onClose={handleClose3} fullWidth maxWidth="sm">
            <DialogTitle>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                        Vikriti Assessment Result
                    </Typography>
                    <IconButton onClick={handleClose3}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>

            <div style={{ padding: '16px', textAlign: 'center' }}>
                <div>
                    <TextField
                        id="standard-search"
                        label="Dominant"
                        value={user?.Vikriti_details?.Result}
                        type="search"
                        onChange={(e) => {
                            const newValue = e.target.value
                            setuser((prevState) => ({
                                ...prevState,
                                Vikriti_details: {
                                    ...prevState.Vikriti_details,
                                    Result: newValue
                                }
                            }));
                        }}

                        variant="standard"
                    />
                </div>

                <div className='pinput-c'>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ width: '16px', height: '16px', backgroundColor: '#FF9800', marginRight: '8px' }}></div>
                            Vata
                        </div>
                        <input
                            type="number"
                            style={{
                                padding: '8px',
                                border: '1px solid #ccc',
                                width: '100px',
                                textAlign: 'center',
                                background: '#F4F4EE'
                            }}
                            onKeyPress={handleKeyPress}
                            value={user?.Vikriti_details?.vataAssScore}
                            onChange={(e) => {
                                const newValue = e.target.value
                                setuser((prevState) => ({
                                    ...prevState,
                                    Vikriti_details: {
                                        ...prevState?.Vikriti_details,
                                        vataAssScore: parseInt(newValue)
                                    }
                                }));
                            }}
                        />
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ width: '16px', height: '16px', backgroundColor: '#795548', marginRight: '8px' }}></div>
                            Pitta
                        </div>
                        <input
                            type="number"
                            style={{
                                padding: '8px',
                                border: '1px solid #ccc',
                                width: '100px',
                                textAlign: 'center',
                                background: '#F4F4EE'
                            }}
                            onKeyPress={handleKeyPress}

                            value={user?.Vikriti_details?.pittaAssScore}
                            onChange={(e) => {
                                const newValue = e.target.value
                                setuser((prevState) => ({
                                    ...prevState,
                                    Vikriti_details: {
                                        ...prevState?.Vikriti_details,
                                        pittaAssScore: parseInt(newValue)
                                    }
                                }));
                            }}
                        />
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ width: '16px', height: '16px', backgroundColor: '#8DE995', marginRight: '8px', }}></div>
                            Kapha
                        </div>

                        <input
                            type="number"
                            style={{
                                padding: '8px',
                                border: '1px solid #ccc',
                                width: '100px',
                                textAlign: 'center',
                                background: '#F4F4EE'
                            }}

                            onKeyPress={handleKeyPress}

                            value={user?.Vikriti_details?.kaphaAssScore}
                            onChange={(e) => {
                                const newValue = e.target.value
                                setuser((prevState) => ({
                                    ...prevState,
                                    Vikriti_details: {
                                        ...prevState?.Vikriti_details,
                                        kaphaAssScore: parseInt(newValue)
                                    }
                                }));
                            }}

                        />
                    </div>

                </div>



                <Button

                    sx={{
                        textTransform: 'none',
                        borderRadius: '0',
                        backgroundColor: '#DA6E0B',
                        color: 'white',
                        border: 'none',
                        boxSizing: 'border-box',
                        borderRadius: "5px",
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 500,
                        padding: "10px",
                        '&:hover': {
                            backgroundColor: '#DA6E0B',
                        }
                    }}

                    onClick={UpdateVkikriti}
                >
                    Save
                </Button>
            </div>
        </Dialog>
    </div>

}

export default Patient_Profile