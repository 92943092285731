import create from 'zustand';

const persistMiddleware = (config) => (set, get, api) => config(
    (args) => {
        set(args);
        sessionStorage.setItem('Reports', JSON.stringify(get()));
    },
    get,
    api
);


const useReportStore = create(persistMiddleware((set) => ({
    selectedReport: JSON.parse(sessionStorage.getItem('Reports'))?.selectedReport || null,
    setSelectedReport: (report) => set({ selectedReport: report }),
    selectedReportUrl: JSON.parse(sessionStorage.getItem('Reports'))?.selectedReportUrl || null,
    setSelectedReportUrl: (report) => set({ selectedReportUrl: report }),
})));

export default useReportStore;
