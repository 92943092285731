import React from 'react'
import useReportStore from '../DataRepository/Reports';
import { useEffect, useState } from 'react'

const initBeforeUnLoad = (showExitPrompt) => {
    window.onbeforeunload = (event) => {
        if (showExitPrompt) {
            const e = event || window.event;
            e.preventDefault();
            if (e) {
                e.returnValue = "";
            }
            return "";
        }
    };
};

function ViewReport() {
    const { selectedReport, selectedReportUrl } = useReportStore()

    const [showExitPrompt, setShowExitPrompt] = useState(true);

    window.onload = function () {
        initBeforeUnLoad(showExitPrompt);
    };

    useEffect(() => {
        initBeforeUnLoad(showExitPrompt);
        return () => {
            window.onbeforeunload = null;
        };
    }, [showExitPrompt]);

    return <div className='Reports-main-page'>
        <div className='Reports-text-box'>
            <p>{selectedReport?.ReportType}</p>
            <div className='flex flex-row justify-center align-items-center display-report'  >
                {
                    selectedReport.filetype === 'images' ?
                    <div className='viewreportsectionimgcontainer'>
                        <img src={selectedReportUrl}></img>   
                    </div>
                      :
                        <iframe src={`https://docs.google.com/gview?url=${selectedReportUrl}&embedded=true`} width={"100%"} height={"100%"}  ></iframe>
                }
            </div>
        </div>

    </div>
}

export default ViewReport