import React from 'react'
import { Button } from '@mui/material'
import { useState } from 'react'
import OTPInput from 'react-otp-input'
import axios from 'axios'
import Cookies from 'js-cookie'
import { setTokens } from './AuthUtil'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
function OTP() {
    let { id } = useParams
    let navigate = useNavigate()
    const [otp, setOtp] = useState('');
    const [error, setError] = useState('');

    const [timer, setTimer] = useState(0);
    const [isWaiting, setIsWaiting] = useState(false);
    let user = Cookies.get('user')

    function checkAndReturnType(input) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
        if (emailRegex.test(input)) {
            return 'Email';
        }
        
        return "Phone"
    }
    
    useEffect(() => {
        let countdown;
        if (isWaiting && timer > 0) {
            countdown = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);
        } else if (timer === 0 && isWaiting) {
            setIsWaiting(false);
        }
        return () => clearInterval(countdown);
    }, [timer, isWaiting]);


    // validate the otp input
    const validateInput = () => {
        if (otp.length === 6) {
            return true
        }
        else {
            setError('Invalid input. Please enter a valid 6 digit OTP');
        }
    }

    // Resend the OTP
    const RequestOTP = async (event) => {
        try {
            let res = await axios.post("https://qvgt79a302.execute-api.ap-south-1.amazonaws.com/dev/Passwordless-signin", {
                user
            })

            if (res.status == 200) {
                Cookies.set('OTP-Session', res?.data?.session);
                
                setIsWaiting(true);
                setTimer(30);
                setOtp('')
                setError('')
            }

        }
        catch (error) {
            setError(error?.response?.data?.error)
        }
    };

    // verify the otp entered by the user
    const VerifyOTP = async (event) => {
        event.preventDefault();
        if (validateInput(otp)) {
            try {
                let sessionOTP = Cookies.get('OTP-Session')

                let res = await axios.post("https://qvgt79a302.execute-api.ap-south-1.amazonaws.com/dev/verify-Passwordless-signin", {
                    user,
                    session: sessionOTP,
                    otp
                })
                if (res.status == 200) {
                    Cookies.set('refreshToken', res?.data?.refreshToken);
                    const { accessToken, refreshToken, id } = res.data;
                    setTokens(accessToken, refreshToken);
                    Cookies.set("id", res?.data?.id);
                    Cookies.remove('OTP-Session');
                    
                    let doctor_response = await axios.get(`https://z2t1vcmobj.execute-api.ap-south-1.amazonaws.com/dev/getDoctor_details/${id}`)
                    
                    if (doctor_response.status === 200) {
                        Cookies.set('Doctor_details', JSON.stringify(doctor_response.data));
                        navigate('/')
                    }
                }
            }
            catch (error) {
                if(error?.response?.data?.error==="NotAuthorizedException"){
                    setError("Incorrect OTP")
                }
                else{
                    setError(error?.response?.data?.error)
                }
            }
        }
    };



    return <div className='Login-page'>
        <nav>
            <div>
                <img
                    src="https://v2.aiwell.in/static/media/Aiwell%20version%202%20website%20logo.d98414bba22690b67307.png"
                    className={`overflow-hidden transition-all w-40`}
                    alt=""
                />
            </div>
        </nav>
        <div className='Login-content-items'>
            <p>Authenticate Code</p>
            <p>Enter the OTP sent your {checkAndReturnType(user)}</p>

            <div className='login-otp-text-input'>
                <OTPInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    inputStyle={{
                        border: "1px solid transparent",
                        borderRadius: "8px",
                        width: "54px",
                        height: "54px",
                        fontSize: "16px",
                        color: "#000",
                        fontWeight: "500",
                        outline: "1px solid silver",
                        caretColor: "blue"
                    }}

                    renderSeparator={<span style={{ visibility: "hidden" }}>--</span>}
                    renderInput={(props) => <input {...props} />}
                />
                <div>
                    <p style={
                        isWaiting ? { visibility: 'visible' } : { visibility: "hidden" }
                    }>{timer} s</p>

                    <p onClick={RequestOTP}>Resend Code</p>
                </div>
            </div>

            {error && <p style={{ color: 'red' }}>{error}</p>}

            <div className='login-action-buttons'>
                <Button
                    style={{
                        width: '100%',
                        textTransform: 'none',
                        borderRadius: '0',
                        backgroundColor: '#DA6E0B',
                        color: 'white',
                        border: 'none',
                        boxSizing: 'border-box',
                        borderRadius: "5px",
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 500,
                        padding: "10px"
                    }}
                    onClick={VerifyOTP}
                >
                    Sign in
                </Button>
            </div>
            <p>By signed In you accept the terms & conditions</p>
        </div>
    </div>
}

export default OTP